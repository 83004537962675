import axios from "axios";
import { JWT_TOKEN_KEY } from "./jwt-token-access";
import { getCookie, getUserToken, getVisitorUniqueId, safeLocalStorage } from "components/helper";

//apply base url for axios
export const API_URL = process.env.REACT_APP_API_URL;

export const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

function setHTTPHeader(config) {
  let visitor_id = getVisitorUniqueId()
  let external_conversation_id = getCookie("external_conversation_id");

  let headers = {
    "X-Risa-Visitor-Id": visitor_id,
    "X-Risa-External-Conversation-Id": external_conversation_id,
    Authorization: getUserToken(),
  }

  if (window.risa_account) {
    const { additional_header } = window.risa_account
    if (additional_header) {
      Object.keys(additional_header).map(key => {
        headers[key] = additional_header[key]
      })
    }
  }

  if ('headers' in config) {
    config['headers'] = {
      ...config['headers'],
      ...headers,
    }
  } else {
    config['headers'] = headers
  }

  return config
}

export const handleErrorRequest = (error) => {
  if (error.detail) {
    return error.detail;
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return error.response.data.detail;
  } else if (error.request) {
    if (error.request.status === 404) {
      return error.request.statusText;
    }

    if (error.request.status === 0) {
      return "Internal Server Error";
    }
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request.statusText;
  }

  // Something happened in setting up the request that triggered an Error
  return "Internal Server Error";
};

export async function get(url, config = {}) {
  config = setHTTPHeader(config);
  return await axiosApi
    .get(url, { ...config })
    .then((response) => {
      let { data, headers } = response;
      return data;
    })
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function post(url, data, config = {}) {
  let headers = setHTTPHeader(config);
  return axiosApi
    .post(url, { ...data }, { ...headers })
    .then((response) => response.data)
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function put(url, data, config = {}) {
  config = setHTTPHeader(config);
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function del(url, config = {}) {
  config = setHTTPHeader(config);
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function uploadFile(url, file) {
  var formData = new FormData();
  formData.append("file", file);
  return await axiosApi
    .post(url, formData, {
      headers: {
        Authorization: "Bearer " + safeLocalStorage(JWT_TOKEN_KEY),
      },
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
}
