import React, { useEffect, useCallback, useState } from 'react';
import {
  Avatar,
  Conversation,
  Search,
  Sidebar,
  ConversationList,
  Status,
} from '@chatscope/chat-ui-kit-react';
import { connect, useDispatch } from 'react-redux';
import { find } from 'lodash';
import DropdownCheckbox from '../DropdownCheckbox';
import { setActivePlatforms } from 'store/actions';

// action reducer
import { fetchConversationAction, getConversationAction } from 'store/actions';
import moment from 'moment';

const ChatLeftSidebarUI = ({
  conversations,
  isFetching,
  totalConversation,
  users,
  fetchConversationAction,
  getConversationAction,
  conversationDetail,
  platforms,
  selectedPlatforms,
}) => {
  // set state
  const [page, setPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(isFetching);
  const [activeFilter, setActiveFilter] = useState(0);
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState([]);

  const getList = useCallback(() => {
    dispatch(
      fetchConversationAction(
        selectedPlatforms.join(','),
        selectedStatus.join(','),
        page
      )
    );
  }, [
    dispatch,
    fetchConversationAction,
    page,
    selectedPlatforms,
    selectedStatus,
  ]);

  useEffect(() => {
    getList();
  }, [dispatch, fetchConversationAction, getList, page, selectedStatus]);

  useEffect(() => {
    setIsLoadMore(isFetching);
  }, [isFetching]);

  const handleOnClick = (conv) => {
    dispatch(getConversationAction(conv.id));
  };

  const handleLoadMore = useCallback(() => {
    if (isLoadMore || isFetching) {
      return;
    }

    if (!totalConversation || totalConversation <= conversations.length) {
      return;
    }

    setIsLoadMore(true);
    let newPage = page;
    newPage += 1;
    setPage(newPage);
    dispatch(
      fetchConversationAction(
        selectedPlatforms.join(','),
        selectedStatus.join(','),
        newPage
      )
    );
  }, [
    conversations.length,
    dispatch,
    fetchConversationAction,
    isFetching,
    isLoadMore,
    page,
    selectedPlatforms,
    selectedStatus,
    totalConversation,
  ]);

  const handleOnSelectFilter = (value) => {
    setActiveFilter(value);
  };

  const handleOnSeletectedPlatforms = (cat) => {
    dispatch(setActivePlatforms(cat));
  };

  return (
    <div>
      <div className="p-2 mt-1 mb-1 bg-white">
        <DropdownCheckbox
          list={platforms}
          selectedPlatforms={selectedPlatforms || []}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setSelectedPlatforms={handleOnSeletectedPlatforms}
        />

        <div
          className="filter"
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <div
            onClick={() => handleOnSelectFilter(0)}
            className={activeFilter !== 1 ? 'active' : ''}
            style={{
              width: '50%',
            }}
          >
            Semua Pesan
          </div>
          <div
            onClick={() => handleOnSelectFilter(1)}
            className={activeFilter === 1 ? 'active' : ''}
            style={{
              width: '50%',
            }}
          >
            Admin
          </div>
        </div>
      </div>
      <ConversationList
        scrollable
        loadingMore={isLoadMore}
        // onYReachEnd={handleLoadMore}
      >
        {conversations?.length === 0 && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <p>Belum ada percakapan atau pesan tidak ditemukan.</p>
          </div>
        )}
        {conversations?.length > 0 &&
          conversations?.map((conv) => {
            const user = find(users, { id: conv.user_creator_id });
            const platform = find(platforms, { id: conv.platform_id });
            let flag = null;
            let className = '';
            let fullname = user.fullname;

            if (conv.status === 'closed') {
              className = 'closed';
              flag = <div className="close-flag">Chat Selesai</div>;
            }

            if (conv.is_human_takeover && conv.status !== 'closed') {
              className += ' is_human_takeover';
              flag = <div className="admin-flag">Diambil Alih Admin</div>;
            }

            if (conv.need_human_takeover && conv.status !== 'closed') {
              className += ' need_human_takeover';
              flag = <div className="admin-flag">Butuh Bantuan Admin</div>;
            }

            if (activeFilter === 1 && !conv.is_human_takeover) {
              return null;
            }

            return (
              <Conversation
                onClick={() => handleOnClick(conv)}
                key={conv.id}
                className={className}
                name={
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    {fullname} {flag}
                  </div>
                }
                lastActivityTime={moment(conv.last_message_date).fromNow()}
                active={conv.id === conversationDetail.id}
                info={conv.last_message_text ? conv.last_message_text : ''}
              >
                <Avatar
                  src={
                    platform.platform_name !== 'web'
                      ? `${process.env.PUBLIC_URL}/images/${platform?.platform_name}-logo.svg`
                      : `${process.env.PUBLIC_URL}/images/web-icon.svg`
                  }
                />
              </Conversation>
            );
          })}
      </ConversationList>
    </div>
  );
};

const mapStateToProps = ({ Conversations, ConversationDetail }) => {
  const { conversations, users, total_conversation, platforms } =
    Conversations.data;

  return {
    conversations: conversations,
    users: users,
    totalConversation: total_conversation,
    platforms: platforms,
    isFetching: Conversations.isLoading,
    conversationDetail: ConversationDetail.conversation,
    selectedPlatforms: Conversations.selectedPlatforms,
  };
};

export default connect(mapStateToProps, {
  fetchConversationAction,
  getConversationAction,
})(ChatLeftSidebarUI);
