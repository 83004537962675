import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceWorker";
import './index.css';
import { Provider } from "react-redux"

import store from "./store"

const sleep = ms => new Promise(r => setTimeout(r, ms));

let el = null
//  makesure the page render completed
for (let index = 0; index < 1000; index++) {
  el = document.getElementById('risa-ai-embed')
  if (!el) {
    await sleep(300)
    continue
  }
  break
}

const root = ReactDOM.createRoot(el);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
serviceWorker.unregister()



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
