import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { put } from 'network/http/api';
import { notificationErrorRequestAction } from 'store/actions';

const ConfirmationModal = ({ conversationId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleAdminTakeOver = () => {
    setIsLoading(true);
    put(`/conversation/human-takeover/${conversationId}`)
      .then(() => {
        setIsLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        dispatch(notificationErrorRequestAction(err));
        setIsLoading(false);
      });
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)} className="label">
        Ambil Alih Chat
      </div>

      {isOpen && (
        <Offcanvas
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          direction="bottom"
          style={{
            height: '280px',
          }}
        >
          <OffcanvasHeader>Konfirmasi Ambil Alih Chat?</OffcanvasHeader>
          <OffcanvasBody>
            <p className="end-chat-container-desc">
              Pastikan Anda telah menjawab pertanyaan pelanggan Anda dalam
              menyelesaikan masalah
            </p>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                disabled={isLoading}
                onClick={handleAdminTakeOver}
                color="primary"
              >
                Ambil Alih Sesi Chat oleh Admin
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => setIsOpen(false)}
                color="primary"
                outline
              >
                Batal Ambil Alih Chat
              </Button>
            </div>
          </OffcanvasBody>
        </Offcanvas>
      )}
    </>
  );
};

export default ConfirmationModal;
