import { get } from "network/http/api";
import React, { useEffect, useState } from "react";

const ZendeskTicketInformation = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [zendesk, setZendesk] = useState({})

    useEffect(() => {
        handleOnFetch()
    }, [])

    const handleOnFetch = () => {
        setIsLoading(true)
        get("/integration/zendesk")
            .then(resp => {
                const { id } = resp

                setIsLoading(false)
                if (!id) return
                setZendesk(resp)
            })
            .catch(error => {
                console.error(error.detail)
                setIsLoading(false)
                // setErrorMessage("Terjadi kesalahan, mohon refresh halaman ini.")
            })
    }

    if (!zendesk || !id) return null

    return (
        <div className="flex flex-col w-full gap-1">
            <p className="label">link to zendesk</p>
            <a className="value" target="_blank" href={`https://${zendesk.subdomain}.zendesk.com/agent/tickets/${id}`}> ticket zendesk </a>
        </div>
    )

}

export default ZendeskTicketInformation