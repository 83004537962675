import React from 'react';
import {
  MessageSeparator,
  MessageGroup,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import { chatDashboardSystemMessage } from 'store/utils/systemMessages';
import MessageBubbleContainer from './MessageBubbleContainer';

const messageBubbleUIKit = (
  user_message,
  scrollToBottom,
  selectedMessage,
  key
) => {
  // key is used for react child list
  const bubbleActive = (message) => {
    let style = {};

    if (selectedMessage) {
      style = {
        borderWidth: 2,
        borderStyle: 'solid',
        padding: 1,
        borderRadius: 10,
        borderColor:
          selectedMessage.id === message.id ? 'green' : 'transparent',
      };
    }
    return style;
  };

  if (
    user_message.messages[0]?.type === 'separator' &&
    user_message.messages[0].message ===
      chatDashboardSystemMessage.adminTakeover
  ) {
    return (
      <MessageSeparator content="Content from property">
        <div className="admin-takeover">{user_message.messages[0].message}</div>
      </MessageSeparator>
    );
  }

  if (user_message.system) {
    return <MessageSeparator content="Risa Exit Conversation" />;
  }

  const sender = user_message.user;
  const messageDirectionQuestion =
    user_message.messages[0]?.is_question || sender?.user_type === 'admin';

  return (
    <MessageGroup
      key={key}
      direction={messageDirectionQuestion ? 'incoming' : 'outgoing'}
    >
      <MessageGroup.Messages>
        {user_message.messages.map((message, idx) => {
          return (
            <MessageBubbleContainer
              key={`${message.external_id}-${idx}`}
              sender={sender}
              scrollToBottom={scrollToBottom}
              style={bubbleActive(message)}
              className={message.is_sended ? 'loading' : ''}
              message={message}
            />
          );
        })}
      </MessageGroup.Messages>
    </MessageGroup>
  );
};

export default messageBubbleUIKit;
