import React, { useEffect, useState } from "react"
import MessageBubbleProduct from "./MessageBubbleProduct"
import MessageBubbleText from "./MessageBubbleText"
import { connect } from "react-redux"
import { addMessage } from "store/chat/actions"
import MessageBubbleProductList from "./MessageBubbleProductList"
import { MessageSeparator } from "@chatscope/chat-ui-kit-react"
import RefreshIcon from "components/Icon/RefreshIcon"

const MessageBubbleContainer = ({ addMessage, message, sender, scrollToBottom, style }) => {
    const handleOnRetryMessage = () => {
        addMessage(message)
    }


    // console.log('sendMessageFailed', isMessageError, sendMessageFailed, message.external_id)
    let classNames = `message-bubble-container ${message.type} ${message.external_id}`
    if (message.is_failed) {
        classNames += " error"
    }

    return (
        <div className={classNames}>

            {message.is_failed &&
                <div className='bubble-error' onClick={() => handleOnRetryMessage()}>
                    <RefreshIcon />
                </div>
            }
            {(message.type === 'product') &&
                <MessageBubbleProduct
                    key={message.external_id}
                    sender={sender}
                    scrollToBottom={scrollToBottom}
                    style={style}
                    className={message.is_sended ? 'loading' : ''}
                    message={message} />
            }

            {(message.type === 'product_list') &&
                <MessageBubbleProductList
                    key={message.external_id}
                    sender={sender}
                    style={style}
                    className={message.is_sended ? 'loading' : ''}
                    message={message} />
            }

            {(message.type === 'text') &&
                <MessageBubbleText
                    key={message.external_id}
                    sender={sender}
                    style={style}
                    className={message.is_sended ? 'loading' : ''}
                    message={message} />
            }
        </div>
    )
}


const mapStateToProps = ({ chat }) => ({
    'sendMessageFailedIds': chat.sendMessageFailedIds
})


export default connect(mapStateToProps, {
    addMessage
})(MessageBubbleContainer)