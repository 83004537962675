import React, { memo, useCallback, useEffect, useState } from "react";
import { Message } from "@chatscope/chat-ui-kit-react";
import moment from "moment";
import MessageBubbleFooter from "./MessageBubbleFooter";
import Skeleton from "react-loading-skeleton";
import { get } from "network/http/api";
import Currency from "components/Currency";
import { getCookie } from "components/helper";
import Chip from "components/Chip";
import BubbleProductItem from "./BubbleProductItem";

const ImageProduct = memo(({ message, product }) => {
  let url = message.attachment.image_url;
  if (product.pictures.length > 0) {
    url = product.pictures[0].original_url;
  }

  return <img className="image-product" src={url} alt={product.name} />;
});

const MessageBubbleProduct = ({ sender, scrollToBottom, message, style }) => {
  return (
    <Message
      key={message.id}
      style={style}
      className="bubble-product"
      model={{
        message: message.message,
        sentTime: moment(message.created_at).fromNow(),
        sender: sender.fullname,
        position: "normal",
      }}
    >
      <Message.CustomContent style={{ width: "100%" }}>
        <BubbleProductItem productId={message.message} />
      </Message.CustomContent>
      <Message.Footer sentTime={moment(message.created_at).fromNow()}>
        <MessageBubbleFooter message={message} />
      </Message.Footer>
    </Message>
  );
};

export default memo(MessageBubbleProduct);
