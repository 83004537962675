import {
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_MESSAGES,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGE_NEXT_SUCCESS,
  POST_ADD_MESSAGE,
  POST_ADD_MESSAGE_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  SELECT_MESSAGE,
  RESET_MESSAGES_STATE,
  UPDATE_MESSAGE_RECEIVED,
  SELECTED_CONVERSATION,
  CLEAR_MESSAGE,
} from "./actionTypes"

export const resetMessageState = () => ({
  type: RESET_MESSAGES_STATE,
})

export const getChatsSuccess = chats => ({
  type: GET_MESSAGES_SUCCESS,
  payload: chats,
})

export const getChatsFail = error => ({
  type: GET_MESSAGES_FAIL,
  payload: error,
})

export const getGroups = () => ({
  type: GET_GROUPS,
})

export const getGroupsSuccess = groups => ({
  type: GET_GROUPS_SUCCESS,
  payload: groups,
})

export const getGroupsFail = error => ({
  type: GET_GROUPS_FAIL,
  payload: error,
})

export const getMessages = (roomId, lastMessageUnix = 0) => ({
  type: GET_MESSAGES,
  payload: {
    roomId,
    lastMessageUnix,
  },
})

export const getMessagesSuccess = messages => ({
  type: GET_MESSAGES_SUCCESS,
  payload: messages,
})

export const getMessagesNextSuccess = messages => ({
  type: GET_MESSAGE_NEXT_SUCCESS,
  payload: messages,
})

export const getMessagesFail = error => ({
  type: GET_MESSAGES_FAIL,
  payload: error,
})

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
})

export const addMessage = (message) => ({
  type: POST_ADD_MESSAGE,
  payload: {
    message,
  },
})

export const addMessageSuccess = message => ({
  type: POST_ADD_MESSAGE_SUCCESS,
  payload: message,
})

export const addMessageFail = (message, error) => ({
  type: POST_ADD_MESSAGE_FAIL,
  payload: {
    ...error,
    message: message,
  },
})

export const updateMessageReceived = message => ({
  type: UPDATE_MESSAGE_RECEIVED,
  payload: message,
})

export const selectMessage = message => ({
  type: SELECT_MESSAGE,
  payload: message,
})

export const selectedConversation = conversationId => ({
  type: SELECTED_CONVERSATION,
  payload: conversationId
})