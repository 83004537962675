import { takeEvery, put, call, select } from "redux-saga/effects"

// Chat Redux States
import { POST_ADD_MESSAGE_SUCCESS } from "store/chat/actionTypes"
import { notificationChatRequestAction } from "./actions"

function* onAddMessageSuccess({ payload }) {
  try {
    put(notificationChatRequestAction(payload))
  } catch (error) {
    console.error(error)
  }
}


function* notificationSaga() {
  yield takeEvery(POST_ADD_MESSAGE_SUCCESS, onAddMessageSuccess)
}

export default notificationSaga
