import React, { memo, useState } from 'react'
import {
  Message,
} from "@chatscope/chat-ui-kit-react";
import moment from 'moment';
import MessageBubbleFooter from './MessageBubbleFooter';
import BubbleProductItem from './BubbleProductItem';


const MessageBubbleProduct = ({ sender, scrollToBottom, message, style }) => {
  const [products, setProducts] = useState(message.message.replace("]", "").replace("[", "").split(',') || [])
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Message key={message.id}
      style={style}
      className='bubble-product'
      model={{
        message: message.message,
        sentTime: moment(message.created_at).fromNow(),
        sender: sender.fullname,
        position: "normal"
      }}
    >
      <Message.CustomContent style={{ width: '100%' }}>
        <div className='product-title'>
          Produk Lainnya
        </div>
        {products.map(p => (
          <BubbleProductItem productId={p} />
        ))}
      </Message.CustomContent>
      <Message.Footer
        sentTime={moment(message.created_at).fromNow()}
      >
        <MessageBubbleFooter
          message={message} />
      </Message.Footer>
    </Message>
  )
}

export default memo(MessageBubbleProduct)
