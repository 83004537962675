import {
  WEBSOCKET_BOT_ANSWER_ERROR,
  WEBSOCKET_BOT_EXIT_CONVERSATION,
  WEBSOCKET_BOT_IS_TYPING,
  WEBSOCKET_FLOATING_SPEECH_EMBED,
} from './actionTypes';

export const actionBotIsTyping = (payload) => ({
  type: WEBSOCKET_BOT_IS_TYPING,
  payload,
});

export const actionBotAnswerError = (payload) => ({
  type: WEBSOCKET_BOT_ANSWER_ERROR,
  payload,
});

export const actionBotExitConversation = (payload) => ({
  type: WEBSOCKET_BOT_EXIT_CONVERSATION,
  payload,
});

export const actionFloatingSpeech = (payload) => ({
  type: WEBSOCKET_FLOATING_SPEECH_EMBED,
  payload,
});
