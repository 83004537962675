import WebSocketClient from 'network/websocket/web_socket_client';
import {
  WEBSOCKET_SUCCESS,
  WEBSOCKET_FAILURE,
  WEBSOCKET_CLOSED,
  INCREMENT_WS_ERROR_COUNT,
  RESET_WS_ERROR_COUNT,
} from './actionTypes';
import store from 'store/index';
import { handleEvent } from './websocket_handlers';
import { JWT_TOKEN_KEY } from 'network/http/jwt-token-access';
import { getCookie, getUserToken } from 'components/helper';
import { actionFloatingSpeech } from './actions';

// WEBSOCKET CONFIG
const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
const MAX_WEBSOCKET_FAILS = 7;

// redux func
const dispatch = store.dispatch;

export async function initialize() {
  if (!window.WebSocket) {
    console.log('Browser does not support websocket'); //eslint-disable-line no-console
    return;
  }

  let connUrl = WEBSOCKET_URL;
  const token = getUserToken();
  WebSocketClient.addMessageListener((msg) => handleEvent(msg, dispatch));
  WebSocketClient.addFirstConnectListener(handleFirstConnect);
  WebSocketClient.addReconnectListener(() => reconnect(false));
  WebSocketClient.addMissedMessageListener(reconnect(false));
  WebSocketClient.addCloseListener(handleClose);
  WebSocketClient.initialize(connUrl, token);
}

function handleClose(failCount) {
  if (failCount > MAX_WEBSOCKET_FAILS) {
    dispatch({ type: WEBSOCKET_CLOSED });
  }
  dispatch({
    type: WEBSOCKET_FAILURE,
    timestamp: Date.now(),
  });
  incrementWsErrorCount();
}

export function close() {
  WebSocketClient.close();
}

export function initializeFloatingSpeech() {
  dispatch(actionFloatingSpeech(true));
}

function reconnectWebSocket() {
  close();
  initialize();
}

export function reconnect(includeWebSocket = true) {
  if (includeWebSocket) {
    reconnectWebSocket();
  }

  dispatch({
    type: WEBSOCKET_SUCCESS,
    timestamp: Date.now(),
  });

  resetWsErrorCount();
}

export function incrementWsErrorCount() {
  return async (dispatch) => {
    dispatch({
      type: INCREMENT_WS_ERROR_COUNT,
    });
  };
}

export function resetWsErrorCount() {
  return async (dispatch) => {
    dispatch({
      type: RESET_WS_ERROR_COUNT,
    });
  };
}

function handleFirstConnect() {
  dispatch({
    type: WEBSOCKET_SUCCESS,
    timestamp: Date.now(),
  });
}
