export const WEBSOCKET_REQUEST = 'WEBSOCKET_REQUEST';
export const WEBSOCKET_SUCCESS = 'WEBSOCKET_SUCCESS';
export const WEBSOCKET_FAILURE = 'WEBSOCKET_FAILURE';
export const WEBSOCKET_CLOSED = 'WEBSOCKET_CLOSED';
export const INCREMENT_WS_ERROR_COUNT = 'INCREMENT_WS_ERROR_COUNT';
export const RESET_WS_ERROR_COUNT = 'RESET_WS_ERROR_COUNT';

// WEBSOCKET EVENT
export const WEBSOCKET_POSTED = 'WEBSOCKET_POSTED';
export const WEBSOCKET_CHANNEL_CREATED = 'WEBSOCKET_CHANNEL_CREATED';
export const WEBSOCKET_BOT_IS_TYPING = 'WEBSOCKET_BOT_IS_TYPING';
export const WEBSOCKET_BOT_ANSWER_ERROR = 'WEBSOCKET_BOT_ANSWER_ERROR';
export const WEBSOCKET_BOT_EXIT_CONVERSATION =
  'WEBSOCKET_BOT_EXIT_CONVERSATION';
export const WEBSOCKET_FLOATING_SPEECH_EMBED =
  'WEBSOCKET_FLOATING_SPEECH_EMBED';
