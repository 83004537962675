import { all, fork } from 'redux-saga/effects';

//public
import chatSaga from './chat/saga';
import conversationsSaga from './conversations/saga';
import conversationSaga from './conversation/saga';
import conversationInfoSaga from './conversationInfo/saga';
import notificationSaga from './notification/saga';

export default function* rootSaga() {
  yield all([
    //public
    fork(chatSaga),
    fork(conversationsSaga),
    fork(conversationSaga),
    fork(conversationInfoSaga),
    fork(notificationSaga),
  ]);
}
