import React from "react";


const convertInt2Money = (price) => {
    let Currency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IDR',
    });
    return Currency.format(price)
}

const Currency = ({ price }) => {
    return <span>{convertInt2Money(price)}</span>
}

export default Currency