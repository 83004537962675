import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearMessage } from 'store/chat/actions';
import RefreshIcon from './Icon/RefreshIcon';

const WebSocketStatus = ({ webSocketStatus }) => {
  const [isRetrying, setIsRetrying] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    if (webSocketStatus === 'connected') {
      setIsRetrying(false);
      setIsClosed(false);
      return;
    }

    if (webSocketStatus === 'disconnected') {
      setIsRetrying(false);
      setIsClosed(true);
      return;
    }

    if (webSocketStatus === 'failure' && !isClosed) {
      setIsRetrying(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketStatus]);

  const handleOnRefresh = () => {
    window.location.reload();
  };

  console.log('isRetrying', isRetrying, isClosed, webSocketStatus);
  if (webSocketStatus === '') {
    return null;
  }

  if (isClosed) {
    return (
      <div className="websocket-status disconnected">
        <h3>Oops, we encountered a hiccup with our server</h3>
        <small className="text-muted">
          It's like a temporary roadblock, could you kindly check your
          connection?
        </small>
        <br />
        <br />
        <div className="button">
          Refresh
          <i onClick={() => handleOnRefresh()} className="fa fa-sync" />
        </div>
      </div>
    );
  }
  return (
    <div className={`websocket-status ${webSocketStatus.toLowerCase()}`}>
      {isRetrying && (
        <div>
          <i className="fa fa-sync">
            <RefreshIcon />
          </i>
          <span>Reconnect with server...</span>
        </div>
      )}
      {webSocketStatus.toUpperCase()}
    </div>
  );
};

const mapStateToProps = ({ WebSocket }) => ({
  webSocketStatus: WebSocket.status,
});

export default connect(mapStateToProps, {
  clearMessageAction: clearMessage,
})(WebSocketStatus);
