import React from 'react';
import messageBubbleUIKit from './MessageBubble/MessageBubbleUIKit';
import { MessageSeparator } from '@chatscope/chat-ui-kit-react';
import MessageBubbleSystem from './MessageBubble/MessageBubbleSystem';

const GroupMessage = ({ group, scrollToBottom, selectedMessage, isQuestion }) => {
    if (group.system) {
        return <MessageBubbleSystem group={group} />
    }

    return group.user_messages.map((user_message, ind) => (
        messageBubbleUIKit(user_message, scrollToBottom, selectedMessage, isQuestion, ind)
    ))
}

export default GroupMessage