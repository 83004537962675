export const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (name) => {
  if (getCookie(name)) {
    document.cookie = name + '=;max-age=0';
    return '';
  }
};

function uuidv4() {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const getVisitorUniqueId = () => {
  let name = 'risa_visitor_id';
  let visitor_id = getCookie(name);
  if (!visitor_id || visitor_id === '') {
    return uuidv4();
  }

  // i
  return visitor_id;
};

export const getUserToken = () => {
  if (!window.risa_account) {
    console.error('risa account not found', window.risa_account);
    return '';
  }

  if (window.risa_account.client_id) {
    let client_id = window.risa_account.client_id;
    let secret = window.risa_account.secret;

    let authorization = btoa(`${secret}:${client_id}`);
    return 'Bearer ' + authorization;
  }

  if (!window.risa_account.authorization) {
    console.error('user token not found', window.risa_account);
    return '';
  }

  return 'Bearer ' + window.risa_account.authorization;
};

export const safeLocalStorage = (key) => {
  if (canAccessLocalStorage()) {
    return localStorage.getItem(key);
  }

  return '';
};

function canAccessLocalStorage() {
  try {
    localStorage.getItem('test');
    return true;
  } catch {
    return false;
  }
}

export const getQueryFromURL = new Proxy(
  new URLSearchParams(window.location.search),
  {
    get: (searchParams, prop) => searchParams.get(prop),
  }
);

export const setQueryURL = (key, value) => {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set(key, value);

  window.location.search = urlParams;
};
