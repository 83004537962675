import React from "react";
import { Message, MessageSeparator } from "@chatscope/chat-ui-kit-react";
import MessageBubbleFooter from "./MessageBubbleFooter";

const MessageBubbleSystem = ({ group }) => {
  let { system, date } = group;
  return <MessageSeparator className={system.type} content={system.message} />;
};

export default MessageBubbleSystem;
