import { combineReducers } from 'redux';

//chat
import chat from './chat/reducer';

// conversation detail
import conversation from './conversation/reducer';

// conversation list
import conversations from './conversations/reducer';

// Websocket
import WebSocket from './websocket/reducer';

// notification
import notification from './notification/reducer';

// conversation info
import conversationInfo from './conversationInfo/reducer';

const rootReducer = combineReducers({
  chat,
  ConversationDetail: conversation,
  Conversations: conversations,
  WebSocket,
  Notification: notification,
  ConversationInfo: conversationInfo,
});

export default rootReducer;
