import { getUserToken, safeLocalStorage } from "components/helper"

export const JWT_TOKEN_KEY = 'risa_api_key'
export default function authHeader() {
  const accessToken = getUserToken()

  if (accessToken) {
    return { Authorization: accessToken }
  } else {
    return {}
  }
}
