import * as React from 'react';

const AvatarRisa = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={500}
    height={500}
    viewBox="0 0 375 375"
    {...props}
  >
    <path d="M172 .6c-25.6 3.2-39 6.5-57.6 14.3C32.3 49.6-14 137.9 4 225.5c16 78 79.5 136.8 159.8 147.9 86.2 12 171.6-41 200.6-124.2 29.3-84.1-4.4-176.7-81-222.7-19.6-11.8-43.8-20.6-65.7-24C207.7.9 178.9-.2 172 .6zm40.6 107.7c24.2 8.1 37.3 26.6 37.4 52.7 0 19.4-8.8 36.2-24 46-2.8 1.8-5 3.5-5 3.9 0 .4 7.2 11.6 16 24.9l15.9 24.2h-17.7l-17.7-.1-14.3-21.4L189 217h-30v43h-33v-73h35.8c39.7 0 40.2-.1 46.9-7 5.5-5.6 6.8-9.4 6.8-19.5 0-9.9-1.7-14.6-7.2-19.6-7.4-6.9-13.7-7.9-52.5-7.9H126v-28.1l39.3.3 39.2.4 8.1 2.7z" />
  </svg>
);
export default AvatarRisa;
