import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
// import wsMiddleware from './websocket/middleware'
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import logger from 'redux-logger'

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(
    sagaMiddleware,
    logger,
    // wsMiddleware
  ))
);
sagaMiddleware.run(rootSaga);

export default store;
