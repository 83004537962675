import { takeEvery, put, call, select } from 'redux-saga/effects';

// Chat Redux States
import { FETCH_CONVERSATION } from './actionTypes';
import * as actions from './actions';
import * as api from './api';

function* onFetchConversation({
  payload: {per_page},
}) {
  try {
    const conversations = yield select((state) => state.Conversations)
    if(conversations.isLoading){
      return
    }

    yield put(actions.fetchConversationRequestAction())
    
    const {status, platform} = conversations.filter
    const response = yield call(
      api.fetchConversation,
      platform,
      status,
      per_page,
      conversations.page,
    );

    
    if (response) {
      let conversationPage = yield select((state) => state.Conversations.page)
      conversationPage += 1
      yield put(actions.setPage(conversationPage));
      yield put(actions.fetchConversationSuccessAction(response));
    }
  } catch (error) {
    yield put(actions.fetchConversationFailedAction(error));
  }
}

function* conversationsSaga() {
  yield takeEvery(FETCH_CONVERSATION, onFetchConversation);
}

export default conversationsSaga;
