const { get, post, put, del } = require("network/http/api")

export const fetchConversationMessage = (idConversation, lastMessageUnix = 0) => {
  return get(`/embed/v1/chat/list/${idConversation}?last_message_unix=${lastMessageUnix}`)
}

export const addNewConversation = (conversation) => {
  return post("/conversation", conversation)
}

export const newMessage = (message) => {
  return post("/embed/v1/chat/new", message)
}

export const updateConversation = (conversation) => {
  return put("/conversation", conversation)
}

export const deleteConversation = (conversationId) => {
  return del(`/conversation/${conversationId}`)
}
