import {
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_FAILED,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_REQUEST,
  SET_ACTIVE_PLATFORMS,
  SET_PAGE,
  RESET_CONVERSATION,
} from './actionTypes';

const INIT_STATE = {
  data: {
    conversations: [],
    users: [],
    platforms: [],
    total_conversation: 0,
  },
  filter : {
    status: '',
    platform: '',
  },
  total_page: 0,
  page: 1,
  selectedPlatforms: [],
  error: null,
  isLoading: false,
};


export const addConversation = (state, newConversation) => {
  let { conversations } = state.data;
  
  newConversation.map(conv=>{
    conversations.push(conv)
  })

  return conversations

}

const Conversations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CONVERSATION:
      return {
        ...state,
        filter: {
          platform: action.payload.platform,
          status: action.payload.status
        },
        page: (action.payload.platform !== state.filter.platform || action.payload.status !== state.filter.status) ? 1: state.page
      }
    case RESET_CONVERSATION:
      return {
        ...state,
        data: {
          ...state.data,
          conversations: [],
          total_conversation: 0,
        },
        total_page: 0,
        page: 1,
      }
    case FETCH_CONVERSATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_CONVERSATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          conversations: addConversation(state, action.payload.conversations),
          users: action.payload.users,
          platforms: action.payload.platforms,
          total_conversation: action.payload.total_conversation,
        },
        
        // action.payload,
        total_page: Math.ceil(action.payload.total_conversation / 10),
        error: null,
      };
    case FETCH_CONVERSATION_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SET_ACTIVE_PLATFORMS: {
      return {
        ...state,
        selectedPlatforms: action.payload,
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    default:
      return state;
  }
};

export default Conversations;
