import * as React from 'react';
const ActiveStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="none"
    viewBox="0 0 41 40"
    {...props}
  >
    <path
      d="M20.5009 30.433L8.74517 37.0133L11.3707 23.7995L1.47974 14.6527L14.8581 13.0664L20.5009 0.833008L26.1436 13.0664L39.5219 14.6527L29.6311 23.7995L32.2566 37.0133L20.5009 30.433Z"
      fill="#F1D021"
    />
  </svg>
);
export default ActiveStar;
