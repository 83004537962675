import React, { useState } from 'react';
import Image from 'components/Image';
import ChevronDown from './Icon/ChevronDown';

const CustomDropdown = ({ title, options, onSelect, isOpen, handleToggle }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <div className="custom-dropdown">
      <span>{title}</span>
      <div className="select" onClick={handleToggle}>
        {selectedOption ? (
          <span>{selectedOption}</span>
        ) : (
          <span style={{ color: '#D9D9D9' }}>Pilih jawaban anda</span>
        )}
        <ChevronDown />
      </div>
      {isOpen && (
        <ul className="options">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option.value)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
