import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { put } from 'network/http/api';
import { notificationErrorRequestAction } from 'store/actions';

const ConfirmationModal = ({ conversationId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleAdminTakeOver = () => {
    setIsLoading(true);
    put(`/conversation/human-takeover/${conversationId}`)
      .then(() => {
        setIsLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        dispatch(notificationErrorRequestAction(err));
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        disabled={isLoading}
        onClick={() => setIsOpen(true)}
        color="primary"
        outline
        style={{
          width: 'fit-content',
        }}
      >
        Ambil Alih Chat
      </Button>

      {isOpen && (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
          <ModalHeader>Konfirmasi Ambil Alih Chat?</ModalHeader>
          <ModalBody>
            <p className="end-chat-container-desc">
              Pastikan Anda telah menjawab pertanyaan pelanggan Anda dalam
              menyelesaikan masalah
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                disabled={isLoading}
                onClick={handleAdminTakeOver}
                color="primary"
              >
                Ambil Alih Sesi Chat oleh Admin
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => setIsOpen(false)}
                color="primary"
                outline
              >
                Batal Ambil Alih Chat
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ConfirmationModal;
