import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

// import actions
import { addMessage, getMessages } from '../store/actions';

// import images
import {
  initialize as initializeWebSocket,
  close as closeWebSocket,
} from 'store/websocket/websocket_action';
import ConversationUIKit from 'components/ChatDashboard/ConversationUIKit';
import { get, post } from 'network/http/api';
import { getCookie, getQueryFromURL, getUserToken } from 'components/helper';
import AlertInfo from 'components/AlertInfo';
import WebSocketStatusNew from 'components/ChatDashboard/WebSocketStatusNew';
import useIsMobile from 'hooks/useIsMobile';
import MobileView from 'components/ChatDashboard/MobileView';

const OmniChannel = ({
  getMessages,
  addMessage,
  conversationDetail,
  messages,
}) => {
  const [currentUserId, setCurrentUserId] = useState(0);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [isError, setIsError] = useState(false);
  const [initialWebSocket, setInitialWebSocket] = useState(false);
  // const [scenarioId, setScenarioId] = useState('')
  const [conversationId, setConversationId] = useState('');
  const isMobile = useIsMobile();

  useEffect(() => {
    // if (conversationDetail.id || isLoadingPage) {
    //     return
    // }
    // createConversation()
    // connect to websocket
    if (!initialWebSocket) {
      // wait until layer rendered
      setTimeout(() => {
        // connect to websocket
        initializeWebSocket();
        setInitialWebSocket(true);
      }, 250);
    }
  }, [initialWebSocket]);

  const handleOnAddMessage = (message) => {
    const data = {
      sender_id: currentUserId,
      created_at: message.created_at,
      // platform_name: 'web',
      type: message.type,
      external_id: message.external_id,
      external_conversation_id: conversationDetail.external_id,
      message: message.message,
      is_question: message.is_question,
    };

    addMessage(data);
  };

  return (
    <React.Fragment>
      <div className="omnichannel">
        <WebSocketStatusNew />
        {isError && (
          <AlertInfo text={'Something went wrong, please refresh this page'} />
        )}

        {/* MOBILE VIEW */}

        {isMobile ? (
          <MobileView
            handleOnAddMessage={handleOnAddMessage}
            isLoadingPage={isLoadingPage}
            getMessages={getMessages}
            addMessage={addMessage}
          />
        ) : (
          <>
            <ConversationUIKit
              disableSidebar={false}
              isQuestion={true}
              disableHeader={false}
              isLoading={isLoadingPage}
              messages={messages}
              onAddMessage={handleOnAddMessage}
              onGetMessages={getMessages}
              conversation={conversationDetail}
              placeholder={
                conversationDetail.is_human_takeover
                  ? 'Tulis jawaban Anda di sini...'
                  : 'Chat dijawab oleh RISA AI secara otomatis'
              }
              disableInput={
                conversationDetail.status === 'closed' ||
                !conversationDetail.is_human_takeover
              }
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ ConversationDetail, chat, WebSocket }) => ({
  chats: chat.chats,
  messages: chat.messages,
  conversationDetail: ConversationDetail.conversation,
  webSocketConnected: WebSocket.status === 'connected',
  botIsTyping: chat.botIsTyping,
});

export default connect(mapStateToProps, {
  addMessage,
  getMessages,
})(OmniChannel);
