import React from 'react';
import { StepperNav } from './StepperComponents/StepperNav';
import moment from 'moment';

const Stepper = ({ history }) => {
  return (
    <div className="step-container">
      {' '}
      <StepperNav
        style={{
          flexWrap: 'unset',
          textAlign: 'left',
        }}
        steps={history.map((el) => ({
          stepContent: () => (
            <div className="step-container">
              <p className="step">{el.text}</p>
              <p className="step-time">
                {moment(el.updated_at).format('DD MMMM YYYY, HH:mm')}
              </p>
            </div>
          ),
          stepStatusCircleSize: 16,
          stepStateColor: 'darkblue',
        }))}
      />
    </div>
  );
};

export default Stepper;
