import React, { memo, useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { get } from 'network/http/api';
import Currency from 'components/Currency';
import { getCookie } from 'components/helper';
import Chip from 'components/Chip';


const ImageProduct = memo(({ product }) => {
  let url = ''
  if (product.pictures.length > 0) {
    url = product.pictures[0].original_url
  }

  return <img className='image-product' src={url} alt={product.name} />
})


const BubbleProductItem = ({ productId }) => {
  const [product, setProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(false)


  const handleOnLoadProduct = useCallback((productId) => {
    console.log('product id', productId)
    if (isLoading || !productId) {
      return
    }

    setIsLoading(true)
    get('/embed/v1/product/detail/' + productId, {
      'headers': {
        'Authorization': getCookie('risa_api_key'),
      }
    })
      .then(resp => {
        setIsLoading(false)
        setProduct(resp)
      })
      .catch(err => {
        console.error(err)
        setIsLoading(false)
      })

  })

  useEffect(() => {
    handleOnLoadProduct(productId)
  }, [])

  if (isLoading || !product) {
    return <Skeleton count={4} />
  }

  return (
    <div className='row'>
      <div className='cell cell--2 image-wrapper'>
        <ImageProduct style={{}} product={product} />
      </div>
      <div className='cell cell--2 product-information'>
        <p className='name text-align-center mb-2'>{product.name}</p>
        <Chip text={product.stock} />
        <br />
        <strong className='price'><Currency price={product.price} /></strong>
      </div>
    </div>
  )
}

export default memo(BubbleProductItem)
