import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearMessage } from 'store/chat/actions';
import RefreshIcon from '../Icon/RefreshIcon';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const WebSocketStatus = ({ webSocketStatus }) => {
  const [isRetrying, setIsRetrying] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    if (webSocketStatus === 'connected') {
      setIsRetrying(false);
      setIsClosed(false);
      return;
    }

    if (webSocketStatus === 'disconnected') {
      setIsRetrying(false);
      setIsClosed(true);
      return;
    }

    if (webSocketStatus === 'failure' && !isClosed) {
      setIsRetrying(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketStatus]);

  const handleOnRefresh = () => {
    window.location.reload();
  };

  if (webSocketStatus === '') {
    return null;
  }

  if (isClosed) {
    return (
      <div className="websocket-status-container disconnected">
        <div className="websocket-status-text">
          Koneksi terputus, mohon tunggu hingga jaringan kembali atau refresh
          halaman
        </div>
      </div>
    );
  }
  return (
    <>
      {isRetrying && (
        <div className="websocket-status-container reconnect">
          <div className="websocket-status-text">
            Sedang menghubungkan koneksi...
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ WebSocket }) => ({
  webSocketStatus: WebSocket.status,
});

export default connect(mapStateToProps, {
  clearMessageAction: clearMessage,
})(WebSocketStatus);
