import * as React from 'react';

const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      d="M6.33594 10.332H7.66927V6.33203H6.33594V10.332ZM7.0026 4.9987C7.19149 4.9987 7.34994 4.9347 7.47794 4.8067C7.60594 4.6787 7.66972 4.52048 7.66927 4.33203C7.66927 4.14314 7.60527 3.9847 7.47727 3.8567C7.34927 3.7287 7.19105 3.66492 7.0026 3.66536C6.81372 3.66536 6.65527 3.72936 6.52727 3.85736C6.39927 3.98536 6.33549 4.14359 6.33594 4.33203C6.33594 4.52092 6.39994 4.67936 6.52794 4.80736C6.65594 4.93536 6.81416 4.99914 7.0026 4.9987ZM7.0026 13.6654C6.08038 13.6654 5.21372 13.4903 4.4026 13.14C3.59149 12.7898 2.88594 12.3149 2.28594 11.7154C1.68594 11.1154 1.21105 10.4098 0.861271 9.5987C0.511493 8.78759 0.336382 7.92092 0.335938 6.9987C0.335938 6.07648 0.511049 5.20981 0.861271 4.3987C1.21149 3.58759 1.68638 2.88203 2.28594 2.28203C2.88594 1.68203 3.59149 1.20714 4.4026 0.857365C5.21372 0.507587 6.08038 0.332476 7.0026 0.332031C7.92483 0.332031 8.79149 0.507142 9.6026 0.857365C10.4137 1.20759 11.1193 1.68248 11.7193 2.28203C12.3193 2.88203 12.7944 3.58759 13.1446 4.3987C13.4948 5.20981 13.6697 6.07648 13.6693 6.9987C13.6693 7.92092 13.4942 8.78759 13.1439 9.5987C12.7937 10.4098 12.3188 11.1154 11.7193 11.7154C11.1193 12.3154 10.4137 12.7905 9.6026 13.1407C8.79149 13.4909 7.92483 13.6658 7.0026 13.6654ZM7.0026 12.332C8.49149 12.332 9.7526 11.8154 10.7859 10.782C11.8193 9.7487 12.3359 8.48759 12.3359 6.9987C12.3359 5.50981 11.8193 4.2487 10.7859 3.21536C9.7526 2.18203 8.49149 1.66536 7.0026 1.66536C5.51372 1.66536 4.2526 2.18203 3.21927 3.21536C2.18594 4.2487 1.66927 5.50981 1.66927 6.9987C1.66927 8.48759 2.18594 9.7487 3.21927 10.782C4.2526 11.8154 5.51372 12.332 7.0026 12.332Z"
      fill="#293448"
    />
  </svg>
);
export default InfoIcon;
