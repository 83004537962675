import * as React from "react"
const SpeechBubbleMessageIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={800}
        height={800}
        viewBox="0 0 512 512"
        {...props}
    >
        <path
            d="M512 217.098C512 88.186 397.392 3.765 256 3.765S0 88.186 0 217.098c0 79.52 55.216 154.785 155.608 213.344l133.02 77.794-13.216-78.402C407.726 422.47 512 340.049 512 217.098z"
            style={{
                fill: "#000",
            }}
        />
    </svg>
)
export default SpeechBubbleMessageIcon
