import { post } from 'network/http/api';
import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
// import './LoginForm.css';

const textWelcome = `Hai, selamat datang di Live Support kami. Tim kami akan merespon kebutuhan kamu segera.
Silakan lengkapi form dibawah ini.`

const EmbedRegisterForm = ({ onSubmit, registerFormStyle }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        setIsLoading(true)

        const data = {
            'name': name,
            'email': email,
            'phone_number': phoneNumber,
            'message': message
        }
        await onSubmit(data)
        setIsLoading(false)
    };

    const isOptionalText = (isRequired) => {
        if (isRequired) {
            return <span className="annotation required">*</span>
        }

        return <span className="annotation">(Optional)</span>
    }



    return (
        <div className='live-chat-form-register'>
            <div className='tagline'>
                {registerFormStyle.description || textWelcome}
            </div>

            <div className="login-form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">{registerFormStyle?.name_field?.label || 'Name'} {isOptionalText(registerFormStyle?.name_field?.required)}</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">{registerFormStyle?.email_field?.label || 'Email'}{isOptionalText(registerFormStyle?.email_field?.required)}</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">{registerFormStyle?.phone_field?.label || 'Phone Number'}{isOptionalText(registerFormStyle?.phone_field?.required)}</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className='action'>
                        <button type="submit" disabled={isLoading}>{isLoading ? 'Loading...' : 'Submit'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmbedRegisterForm;