import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

const messageStatuses = ['active', 'closed'];

const DropdownCheckbox = ({
  list,
  selectedPlatforms,
  setSelectedPlatforms = [],
  selectedStatus,
  setSelectedStatus,
}) => {
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (item) => {
    if (selectedPlatforms?.includes(item)) {
      setSelectedPlatforms(
        selectedPlatforms?.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedPlatforms([...selectedPlatforms, item]);
    }
  };

  const handleStatusCheckboxChange = (item) => {
    if (selectedStatus.includes(item)) {
      setSelectedStatus(
        selectedStatus.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedStatus([...selectedStatus, item]);
    }
  };

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    if (isSelectAll) {
      setSelectedPlatforms([]);
    } else {
      setSelectedPlatforms(list.map((el) => el.platform_name));
    }
  };

  const handleOnChecked = (item) => {
    return selectedPlatforms?.includes(item);
  };

  const handleOnCheckedStatus = (item) => {
    return selectedStatus.includes(item);
  };

  return (
    <div className="dropdown-checkbox-costum" ref={modalRef}>
      <div
        className="dropdown-checkbox"
        onClick={handleToggleDropdown}
        style={{
          maxWidth: '100%',
          overflowX: 'scroll',
        }}
      >
        <span className="dropdown-toggle">
          {selectedPlatforms && selectedPlatforms?.length ? (
            <span>
              Platform:{' '}
              <span style={{ fontWeight: 700 }}>
                {' '}
                {selectedPlatforms?.join(',')}
              </span>
            </span>
          ) : (
            'Semua Chat'
          )}{' '}
          {selectedStatus.length ? (
            <span>
              Status:{' '}
              <span style={{ fontWeight: 700 }}>
                {' '}
                {selectedStatus.join(',')}
              </span>
            </span>
          ) : null}
        </span>
        <ChevronDownIcon className="chevron-down" />
      </div>
      {isOpen && (
        <div className="dropdown-checkbox-list">
          <label className="checkbox-label">
            <input
              className="checkbox-input"
              type="checkbox"
              value={isSelectAll}
              checked={isSelectAll}
              onChange={handleSelectAll}
            />
            Semua Chat
          </label>

          <p className="checkbox-label" style={{ fontWeight: 600 }}>
            Platform
          </p>
          {Boolean(list?.length) && (
            <>
              {list.map((item, index) => (
                <label key={index} className="checkbox-label">
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    value={item.platform_name}
                    checked={handleOnChecked(item.platform_name)}
                    onChange={() => handleCheckboxChange(item.platform_name)}
                  />
                  {item.platform_name}
                </label>
              ))}
            </>
          )}

          <p className="checkbox-label" style={{ fontWeight: 600 }}>
            Status Pesan
          </p>
          {Boolean(messageStatuses?.length) && (
            <>
              {messageStatuses.map((item, index) => (
                <label key={index} className="checkbox-label">
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    value={item}
                    checked={handleOnCheckedStatus(item)}
                    onChange={() => handleStatusCheckboxChange(item)}
                  />
                  {item}
                </label>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownCheckbox;
