import React from 'react';
import { Message } from '@chatscope/chat-ui-kit-react';
import MessageBubbleFooter from './MessageBubbleFooter';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import moment from 'moment';
import rehypeRaw from 'rehype-raw';
import { parseDate } from 'utils/parser';

const MessageBubbleText = ({ sender, message, style }) => {
  const renderMessage = () => {
    const result = message.message.replaceAll('<br>', '\n');

    return String(result);
  };
  return (
    <Message
      key={message.external_id}
      style={{
        ...style,
        border: '1px solid',
        borderColor: message.is_rejected ? 'red' : 'transparent',
      }}
      className={
        message.generated_by_ai || sender.user_type === 'admin'
          ? 'generated_by_ai'
          : ''
      }
      model={{
        message: renderMessage(),
        sender: sender.fullname,
        position: 'normal',
      }}
    >
      <Message.CustomContent>
        <div
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          <Markdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            children={renderMessage()}
            options={{
              createElement(type, props, children) {
                return (
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {React.createElement(
                      type,
                      {
                        style: {
                          whiteSpace: 'pre-wrap',
                        },
                      },
                      children
                    )}
                  </div>
                );
              },
            }}
          />
        </div>
      </Message.CustomContent>

      <Message.Footer sentTime={parseDate(message.created_at)}></Message.Footer>
    </Message>
  );
};

export default MessageBubbleText;
