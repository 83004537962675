import { API_URL } from "network/http/api";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

const Image = ({ style, className, src }) => {
    const [isLoading, setIsLoading] = useState(true)

    const imageLoaded = () => {
        setIsLoading(false)
    }

    let url = API_URL + "/file/image/" + src
    return (
        <>
            {isLoading &&
                <div className="loading"><Skeleton count={3} /></div>
            }
            <img src={url} style={style} className={className} onLoad={imageLoaded} />
        </>
    )
}

export default Image