import { getVisitorUniqueId } from 'components/helper';

const MAX_WEBSOCKET_FAILS = 7;
const MIN_WEBSOCKET_RETRY_TIME = 3000; // 3 sec
const MAX_WEBSOCKET_RETRY_TIME = 300000; // 5 mins
const JITTER_RANGE = 2000; // 2 sec

const WEBSOCKET_HELLO = 'ping';

export default class WebSocketClient {
  messageListeners = new Set();
  firstConnectListeners = new Set();
  reconnectListeners = new Set();
  missedMessageListeners = new Set();
  errorListeners = new Set();
  closeListeners = new Set();

  constructor() {
    this.conn = null;
    this.connectionUrl = null;
    this.responseSequence = 1;
    this.serverSequence = 0;
    this.connectFailCount = 0;
    this.responseCallbacks = {};
    this.connectionId = '';
  }

  // on connect, only send auth cookie and blank state.
  // on hello, get the connectionID and store it.
  // on reconnect, send cookie, connectionID, sequence number.
  initialize(connectionUrl = this.connectionUrl, token) {
    if (this.conn) {
      return;
    }

    if (connectionUrl == null) {
      console.error('websocket must have connection url'); //eslint-disable-line no-console
      return;
    }

    if (this.connectFailCount === 0) {
      console.log('websocket connecting to ' + connectionUrl); //eslint-disable-line no-console
    }

    // Add connection id, and last_sequence_number to the query param.
    // We cannot use a cookie because it will bleed across tabs.
    // We cannot also send it as part of the auth_challenge, because the session cookie is already sent with the request.
    this.conn = new WebSocket(
      `${connectionUrl}?connection_id=${this.connectionId}&sequence_number=${this.serverSequence}`
    );
    this.connectionUrl = connectionUrl;

    this.conn.onopen = () => {
      const { client_id, secret } = window.risa_account;
      if (client_id && secret) {
        this.sendMessage('authentication_challenge', {
          client_id,
          secret,
          user_external_id: getVisitorUniqueId(),
        });
      } else if (token) {
        this.sendMessage('authentication_challenge', { token });
      } else {
        console.error('connection open failed, token not found');
      }

      console.log('this.connectFailCount', this.connectFailCount);
      if (MAX_WEBSOCKET_FAILS > this.connectFailCount) {
        return;
      }

      if (this.connectFailCount > 0) {
        console.log('websocket re-established connection'); //eslint-disable-line no-console

        this.reconnectListeners.forEach((listener) => listener());
      } else if (this.firstConnectListeners.size > 0) {
        this.firstConnectListeners.forEach((listener) => listener());
      }
    };

    this.conn.onclose = () => {
      this.conn = null;
      this.responseSequence = 1;

      if (this.connectFailCount > MAX_WEBSOCKET_FAILS) {
        console.log('websocket closed'); //eslint-disable-line no-console
        return;
      }

      this.connectFailCount++;
      this.closeCallback?.(this.connectFailCount);
      this.closeListeners?.forEach((listener) =>
        listener(this.connectFailCount)
      );

      let retryTime = MIN_WEBSOCKET_RETRY_TIME;

      // If we've failed a bunch of connections then start backing off
      if (this.connectFailCount > MAX_WEBSOCKET_FAILS) {
        retryTime =
          MIN_WEBSOCKET_RETRY_TIME *
          this.connectFailCount *
          this.connectFailCount;
        if (retryTime > MAX_WEBSOCKET_RETRY_TIME) {
          retryTime = MAX_WEBSOCKET_RETRY_TIME;
        }
      }

      // Applying jitter to avoid thundering herd problems.
      retryTime += Math.random() * JITTER_RANGE;

      setTimeout(() => {
        this.initialize(connectionUrl, token);
      }, retryTime);
    };

    this.conn.onerror = (evt) => {
      if (this.connectFailCount <= 1) {
        console.log('websocket error'); //eslint-disable-line no-console
        console.log(evt); //eslint-disable-line no-console
        return;
      }

      this.errorListeners.forEach((listener) => listener(evt));
    };

    this.conn.onmessage = (evt) => {
      const msg = JSON.parse(evt.data);
      console.log('ws onmessage', msg);
      if (msg.seq_reply) {
        // This indicates a reply to a websocket request.
        // We ignore sequence number validation of message responses
        // and only focus on the purely server side event stream.
        if (msg.error) {
          console.log(msg); //eslint-disable-line no-console
        }

        if (this.responseCallbacks[msg.seq_reply]) {
          this.responseCallbacks[msg.seq_reply](msg);
          Reflect.deleteProperty(this.responseCallbacks, msg.seq_reply);
        }
      } else if (this.messageListeners.size > 0) {
        // We check the hello packet, which is always the first packet in a stream.
        if (
          msg.command === WEBSOCKET_HELLO &&
          (this.missedEventCallback || this.missedMessageListeners.size > 0)
        ) {
          console.log('got connection id ', msg.data.connection_id); //eslint-disable-line no-console
          // If we already have a connectionId present, and server sends a different one,
          // that means it's either a long timeout, or server restart, or sequence number is not found.
          // Then we do the sync calls, and reset sequence number to 0.
          if (
            this.connectionId !== '' &&
            this.connectionId !== msg.data.connection_id
          ) {
            console.log(
              'long timeout, or server restart, or sequence number is not found.'
            ); //eslint-disable-line no-console

            this.missedMessageListeners.forEach((listener) => listener());

            this.serverSequence = 0;
          }

          // If it's a fresh connection, we have to set the connectionId regardless.
          // And if it's an existing connection, setting it again is harmless, and keeps the code simple.
          this.connectionId = msg.data.connection_id;
        }

        // Now we check for sequence number, and if it does not match,
        // we just disconnect and reconnect.
        console.log('server sequence', this.serverSequence);
        console.log('message listeners', this.messageListeners);
        console.log('msg seq', msg.seq);
        // if (msg.seq !== this.serverSequence) {
        //   console.log(
        //     "missed websocket event, act_seq=" +
        //       msg.seq +
        //       " exp_seq=" +
        //       this.serverSequence
        //   ) //eslint-disable-line no-console
        //   // We are not calling this.close() because we need to auto-restart.
        //   this.connectFailCount = 0
        //   this.responseSequence = 1
        //   this.conn?.close() // Will auto-reconnect after MIN_WEBSOCKET_RETRY_TIME.
        //   return
        // }
        this.serverSequence = msg.seq + 1;

        if (
          this.messageListeners &&
          Array.from(this.messageListeners).length > 0
        ) {
          Array.from(this.messageListeners)?.forEach((listener) =>
            listener(msg)
          );
        }
      }
    };
  }

  addMessageListener(listener) {
    this.messageListeners.add(listener);
  }

  removeMessageListener(listener) {
    this.messageListeners.delete(listener);
  }

  addFirstConnectListener(listener) {
    this.firstConnectListeners.add(listener);
  }

  removeFirstConnectListener(listener) {
    this.firstConnectListeners.delete(listener);
  }

  addReconnectListener(listener) {
    this.reconnectListeners.add(listener);
  }

  removeReconnectListener(listener) {
    this.reconnectListeners.delete(listener);
  }

  addMissedMessageListener(listener) {
    this.missedMessageListeners.add(listener);
  }

  removeMissedMessageListener(listener) {
    this.missedMessageListeners.delete(listener);
  }

  addErrorListener(listener) {
    this.errorListeners.add(listener);
  }

  removeErrorListener(listener) {
    this.errorListeners.delete(listener);
  }

  addCloseListener(listener) {
    this.closeListeners.add(listener);
  }

  removeCloseListener(listener) {
    this.closeListeners.delete(listener);
  }

  close() {
    this.connectFailCount = 0;
    this.responseSequence = 1;
    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.onclose = () => {}; //eslint-disable-line no-empty-function
      this.conn.close();
      this.conn = null;
      console.log('websocket closed'); //eslint-disable-line no-console
    }
  }

  sendMessage(action, data, responseCallback) {
    const msg = {
      command: action,
      seq: this.responseSequence++,
      data,
    };

    if (responseCallback) {
      this.responseCallbacks[msg.seq] = responseCallback;
    }

    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.send(JSON.stringify(msg));
    } else if (!this.conn || this.conn.readyState === WebSocket.CLOSED) {
      this.conn = null;
      this.initialize();
    }
  }

  userTyping(channelId, parentId, callback) {
    const data = {
      channel_id: channelId,
      parent_id: parentId,
    };
    this.sendMessage('user_typing', data, callback);
  }

  userUpdateActiveStatus(userIsActive, manual, callback) {
    const data = {
      user_is_active: userIsActive,
      manual,
    };
    this.sendMessage('user_update_active_status', data, callback);
  }

  getStatuses(callback) {
    this.sendMessage('get_statuses', null, callback);
  }

  floatingSpeech(callback) {
    this.sendMessage('floating_speech_embed', null, callback);
  }

  getStatusesByIds(userIds, callback) {
    const data = {
      user_ids: userIds,
    };
    this.sendMessage('get_statuses_by_ids', data, callback);
  }
}
