import React, { useEffect, useState } from 'react';
import { Sidebar } from '@chatscope/chat-ui-kit-react';
import { connect, useDispatch } from 'react-redux';
import { post } from 'network/http/api';

// action reducer
import { getConversationInfoAction } from 'store/actions';
import ZendeskTicketInformation from './ZendeskTicketInformation';
import Stepper from './Stepper';
import ExpandIcon from 'components/Icon/ExpandIcon';
import {
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import InfoIcon from 'components/Icon/InfoIcon';

const InformationUserSidebar = ({
  users,
  platforms,
  conversation,
  conversationInfo,
  getConversationInfoAction,
}) => {
  // set state

  const dispatch = useDispatch();
  const [isOpenUserInfoModal, setIsOpenUserInfoModal] = useState(false);
  const [loadingSubmitNotes, setLoadingSubmitNotes] = useState(false);

  const [comment, setComment] = useState('');

  const handleSubmitNotes = () => {
    setLoadingSubmitNotes(true);
    post(`/conversation/${conversation.id}/tag`, {
      label: '',
      note: comment,
      conversation_id: conversation.id,
    })
      .then((resp) => {
        setComment('');
        setLoadingSubmitNotes(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (conversation.id) {
      dispatch(getConversationInfoAction(conversation.id));
    }
  }, [conversation.id, dispatch, getConversationInfoAction]);

  return (
    <Sidebar position="right" scrollable={false}>
      <div className="information-user">
        <div className="p-2 mb-1 title-container">
          <p className="title">Informasi Pelanggan</p>

          <div id="informasi-pelanggan">
            <InfoIcon />
          </div>

          <UncontrolledTooltip placement="bottom" target="informasi-pelanggan">
            Anda dapat melihat informasi mengenai pelanggan Anda di bagian ini.
            Klik tombol perbesar untuk melihat informasi lebih lengkap.
          </UncontrolledTooltip>

          {conversationInfo?.information?.variables?.length > 0 ? (
            <div
              onClick={() => setIsOpenUserInfoModal(true)}
              style={{
                cursor: 'pointer',
              }}
            >
              <ExpandIcon />
            </div>
          ) : null}
        </div>

        <div className="flex flex-col gap-2 p-3">
          {conversationInfo?.information?.variables?.map((el) => {
            return (
              <div className="flex flex-col w-full gap-1">
                <p className="label">{el.variable_name}</p>
                <p className="value">{el.answer}</p>
              </div>
            );
          })}

          <ZendeskTicketInformation id={conversation.zendesk_external_id} />
        </div>
        <div className="flex p-2 mb-1 title-container">
          <p className="title">Topik</p>

          <div id="history">
            <InfoIcon />
          </div>

          <UncontrolledTooltip placement="bottom" target="history">
            Anda dapat melihat perubahan status sesi chat melalui bagian ini.
            Anda dapat memonitor perubahan status chat dari ambil alih, hingga
            chat telah diselesaikan oleh Admin maupun pelanggan.
          </UncontrolledTooltip>
        </div>

        <div className="flex flex-col gap-2 p-3">
          <p className="label-form">Notes</p>
          <textarea
            onChange={handleChangeComment}
            value={comment}
            placeholder="Tambahkan notes Anda di sini... "
            style={{
              marginBottom: '8px',
              minHeight: '100px',
              width: '100%',
            }}
          />

          <button
            disabled={loadingSubmitNotes}
            onClick={handleSubmitNotes}
            className="close-primary"
            style={{
              height: '100%',
            }}
          >
            Submit
          </button>
        </div>

        <div className="flex p-2 mb-1 title-container">
          <p className="title">Histori Perubahan</p>

          <div id="history">
            <InfoIcon />
          </div>

          <UncontrolledTooltip placement="bottom" target="history">
            Anda dapat melihat perubahan status sesi chat melalui bagian ini.
            Anda dapat memonitor perubahan status chat dari ambil alih, hingga
            chat telah diselesaikan oleh Admin maupun pelanggan.
          </UncontrolledTooltip>
        </div>
        <div className="flex flex-col gap-2 p-3">
          <Stepper history={conversationInfo.information.histories || []} />
        </div>
      </div>

      <Modal
        isOpen={isOpenUserInfoModal}
        toggle={() => setIsOpenUserInfoModal(!isOpenUserInfoModal)}
      >
        <ModalHeader>Konfirmasi Ambil Alih Chat?</ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-2 p-3">
            {conversationInfo?.information?.variables?.map((el, idx) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    border: `1px solid black`,
                    borderBottom:
                      idx !==
                      conversationInfo?.information?.variables.length - 1
                        ? 1
                        : 0,
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: '16px',
                    }}
                  >
                    {el.variable_name}
                  </p>
                  <p className="value">{el.answer}</p>
                </div>
              );
            })}

            <ZendeskTicketInformation id={conversation.zendesk_external_id} />
          </div>
        </ModalBody>
      </Modal>
    </Sidebar>
  );
};

const mapStateToProps = ({
  Conversations,
  ConversationDetail,
  ConversationInfo,
}) => {
  const { users, platforms } = Conversations.data;

  return {
    users: users,
    conversation: ConversationDetail.conversation,
    platforms: platforms,
    conversationInfo: ConversationInfo,
  };
};

export default connect(mapStateToProps, {
  getConversationInfoAction,
})(InformationUserSidebar);
