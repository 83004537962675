import { takeEvery, put, call, select } from 'redux-saga/effects';

// Chat Redux States
import { GET_CONVERSATION_INFO } from './actionTypes';
import * as actions from './actions';
import * as api from './api';

function* onGetConversationInfo({ payload: { id } }) {
  try {
    const response = yield call(api.getConversationHistory, id);
    console.log('onGetConversationINFO detail', response);
    yield put(actions.getConversationInfoSuccessAction(response));
  } catch (error) {
    yield put(actions.getConversationInfoFailedAction(error));
  }
}

function* conversationInfoSaga() {
  yield takeEvery(GET_CONVERSATION_INFO, onGetConversationInfo);
}

export default conversationInfoSaga;
