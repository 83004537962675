import React from "react";
import {
  MessageSeparator,
  MessageGroup,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import MessageBubbleContainer from "./MessageBubbleContainer";

const messageBubbleUIKit = (
  user_message,
  scrollToBottom,
  selectedMessage,
  key
) => {
  // key is used for react child list
  if (!user_message.user) {
    return (
      <MessageSeparator
        key={key}
        as="h2"
        className="mt-2 mb-2"
        content={user_message.messages[0].message}
      />
    );
  }

  const bubbleActive = (message) => {
    let style = {};

    if (selectedMessage) {
      style = {
        borderWidth: 2,
        borderStyle: "solid",
        padding: 1,
        borderRadius: 10,
        borderColor:
          selectedMessage.id === message.id ? "green" : "transparent",
      };
    }
    return style;
  };

  if (user_message.system) {
    return <MessageSeparator content="Risa Exit Conversation" />;
  }

  const sender = user_message.user;
  const messageDirectionQuestion = user_message.messages[0].is_question;

  return (
    <MessageGroup
      key={key}
      direction={messageDirectionQuestion ? "outgoing" : "incoming"}
    >
      <MessageGroup.Messages>
        {user_message.messages.map((message) => {
          return (
            <MessageBubbleContainer
              key={message.external_id}
              sender={sender}
              scrollToBottom={scrollToBottom}
              style={bubbleActive(message)}
              className={message.is_sended ? "loading" : ""}
              message={message}
            />
          );
        })}
      </MessageGroup.Messages>
    </MessageGroup>
  );
};

export default messageBubbleUIKit;
