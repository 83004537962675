import * as React from 'react';

const MenuChatIcon = (props) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 10.125C17.7016 10.125 17.4155 10.0065 17.2045 9.7955C16.9935 9.58452 16.875 9.29837 16.875 9C16.875 8.70163 16.9935 8.41548 17.2045 8.2045C17.4155 7.99353 17.7016 7.875 18 7.875C18.2984 7.875 18.5845 7.99353 18.7955 8.2045C19.0065 8.41548 19.125 8.70163 19.125 9C19.125 9.29837 19.0065 9.58452 18.7955 9.7955C18.5845 10.0065 18.2984 10.125 18 10.125ZM18 19.125C17.7016 19.125 17.4155 19.0065 17.2045 18.7955C16.9935 18.5845 16.875 18.2984 16.875 18C16.875 17.7016 16.9935 17.4155 17.2045 17.2045C17.4155 16.9935 17.7016 16.875 18 16.875C18.2984 16.875 18.5845 16.9935 18.7955 17.2045C19.0065 17.4155 19.125 17.7016 19.125 18C19.125 18.2984 19.0065 18.5845 18.7955 18.7955C18.5845 19.0065 18.2984 19.125 18 19.125ZM18 28.125C17.7016 28.125 17.4155 28.0065 17.2045 27.7955C16.9935 27.5845 16.875 27.2984 16.875 27C16.875 26.7016 16.9935 26.4155 17.2045 26.2045C17.4155 25.9935 17.7016 25.875 18 25.875C18.2984 25.875 18.5845 25.9935 18.7955 26.2045C19.0065 26.4155 19.125 26.7016 19.125 27C19.125 27.2984 19.0065 27.5845 18.7955 27.7955C18.5845 28.0065 18.2984 28.125 18 28.125Z"
      stroke="#293448"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default MenuChatIcon;
