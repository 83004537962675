import {
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_REQUEST,
  FETCH_CONVERSATION_FAILED,
  FETCH_CONVERSATION_SUCCESS,
  SET_ACTIVE_PLATFORMS,
  RESET_CONVERSATION,
  SET_PAGE,
} from './actionTypes';

export const fetchConversationRequestAction = ()=>({
  type: FETCH_CONVERSATION_REQUEST,
})

export const resetConversationRequestAction = ()=>({
  type: RESET_CONVERSATION
})

export const fetchConversationAction = (
  platform,
  status = 'active',
  page = 1,
  per_page = 10
) => ({
  type: FETCH_CONVERSATION,
  payload: {
    platform,
    page,
    per_page,
    status,
  },
});

export const fetchConversationSuccessAction = (conversation) => ({
  type: FETCH_CONVERSATION_SUCCESS,
  payload: conversation,
});

export const fetchConversationFailedAction = (error) => ({
  type: FETCH_CONVERSATION_FAILED,
  payload: error,
});

export const setActivePlatforms = (platforms) => ({
  type: SET_ACTIVE_PLATFORMS,
  payload: platforms,
});

export const setPage = (page) => ({
  type: SET_PAGE,
  payload: page,
});
