import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { post } from 'network/http/api';
// import actions
import {
  addMessage,
  getMessages,
  selectedConversation,
  addMessageSuccess,
  getConversationSuccessAction,
  getConversationAction,
} from '../store/actions';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import SpeechBubbleMessageIcon from 'components/Icon/SpeecBubbleMessageIcon';
import MinusIcon from 'components/Icon/MinusIcon';
import ConversationSessionWrapper from 'components/Chat/ConversationSessionWrapper';
import CloseConversation from 'components/Chat/CloseConversation';
import AvatarRisa from 'components/Icon/AvatarRisa';
import { get } from 'network/http/api';
import Image from 'components/Image';
import WebSocketStatusNew from 'components/ChatDashboard/WebSocketStatusNew';
import { initializeFloatingSpeech } from 'store/websocket/websocket_action';

const LiveChatEmbed = ({ botIsTyping, showFloatingSpeech }) => {
  const [disableInput, setDisableInput] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEndChat, setIsOpenEndChat] = useState(false);
  const [setting, setSetting] = useState({
    title: 'RISA AI',
    logo: '',
    primary_color: '#FFFFFF',
    message_color: '#6ea9d7',
  });

  useEffect(() => {
    if (botIsTyping) {
      setDisableInput(true);
      return;
    }
    setDisableInput(false);
  }, [botIsTyping]);

  useEffect(() => {
    if (!showFloatingSpeech) {
      setTimeout(() => initializeFloatingSpeech(), 3000);
    }
  }, [showFloatingSpeech]);

  useEffect(() => {
    get('/embed/v1/conversation/style')
      .then((resp) => {
        if (!resp) {
          return;
        }
        setSetting(resp);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleOnClose = () => {
    setIsOpenEndChat(true);
  };

  return (
    <React.Fragment>
      {showFloatingSpeech && (
        <div className="speechBubble">Apakah ada yang dapat saya bantu?</div>
      )}

      <div className={`live-chat embed` + (isOpen ? ' open' : '')}>
        {isOpen && (
          <Card>
            <CardHeader style={{ backgroundColor: setting['primary_color'] }}>
              {setting['logo'] === '' && <AvatarRisa />}
              {setting['logo'] !== '' && <Image src={setting['logo']} />}
              <span className="title">{setting['title']}</span>
              <style>
                {`
                .live-chat.embed .card .card-header {
                  background-color: ${setting['primary_color']} !important;
                }

                .live-chat.embed .cs-message-group__messages .cs-message .cs-message__content{
                  background-color: ${setting['message_color']};
                }
              `}
              </style>
              <div className="actions">
                <CloseConversation closeTray={handleOnClose} />
                <button onClick={() => setIsOpen(false)} className="minus">
                  <MinusIcon />
                  <span className="tooltiptext">Minimize Conversation</span>
                </button>
              </div>
            </CardHeader>
            <WebSocketStatusNew />
            <CardBody>
              <ConversationSessionWrapper
                registerFormStyle={setting}
                isOpenEndChat={isOpenEndChat}
                setIsOpenEndChat={setIsOpenEndChat}
              />
            </CardBody>
            <CardFooter>
              Power by <a href="https://risa.ai">Risa AI</a>
            </CardFooter>
          </Card>
        )}
        {!isOpen && (
          <div className="tray">
            <div className="liveChatBtn" onClick={() => setIsOpen(true)}>
              <SpeechBubbleMessageIcon />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ ConversationDetail, chat, WebSocket }) => ({
  chats: chat.chats,
  messages: chat.messages,
  conversationDetail: ConversationDetail.conversation,
  webSocketConnected: WebSocket.status === 'connected',
  botIsTyping: chat.botIsTyping,
  showFloatingSpeech: chat.showFloatingSpeech,
});

export default connect(mapStateToProps, {
  addMessageSuccess,
  addMessage,
  getMessages,
  selectedConversation,
  getConversationAction,
  getConversationSuccessAction,
})(LiveChatEmbed);
