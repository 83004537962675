import {
    NOTIFICATION_REQUEST
} from './actionTypes'
const initialState = {
    title: '',
    body: '',
    withSound: '',
    type: 'info'
}


const notification = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_REQUEST:
            state = action.payload;
            break;
        default:
            break;
    }
    return state;
};

export default notification;
