import moment from "moment";
import { find } from "lodash";

export const loadMoreMessageState = (state, messages) => {
  let { count, groups, last_message_unix_time, has_next } = messages;

  const userGroups = state.messages.nextGroups.concat(groups);
  return {
    count,
    nextGroups: userGroups,
    groups: state.messages.groups,
    hasNext: has_next,
    lastMessageUnixTime: last_message_unix_time,
  };
};

export const isMessageUnique = (groups, message) => {
  let messages = extractMessageOnly(groups);
  let found = find(messages, { external_id: message.external_id });

  if (found) {
    return false;
  }
  return true;
};

export const extractMessageOnly = (groups) => {
  let messages = [];
  if (!groups || groups.length < 1) {
    return messages;
  }
  groups.map((group) => {
    group.user_messages.map((userMsg) => {
      // eslint-disable-next-line array-callback-return
      userMsg.messages.map((msg) => {
        messages.push(msg);
      });
    });
  });

  return messages;
};

export const updateMessageGroup = (groups, message) => {
  return groups.map((group) => {
    let userMessages = group.user_messages.map((userMsg) => {
      let messages = userMsg.messages.map((msg) => {
        if (msg.external_id === message.external_id) {
          return message;
        }
        return msg;
      });

      return {
        user: userMsg.user,
        messages: messages,
      };
    });

    return {
      date: group.date,
      user_messages: userMessages,
    };
  });
};

export const updateMessageState = (state, message) => {
  const { messages } = state;
  let { groups, count, hasNext, nextGroups, lastMessageUnixTime } = messages;

  groups = updateMessageGroup(messages.groups, message);
  let msg_group = { groups, count, nextGroups, hasNext, lastMessageUnixTime };
  return msg_group;
};

export const addNewMessage = (state, message) => {
  const { messages } = state;
  const { count, groups, hasNext, nextGroups, lastMessageUnixTime } = messages;

  // if (conversationId !== message.external_conversation_id) {
  //   return messages
  // }

  const date = moment(message.created_at).format("DD MMM YYYY");

  // NOTE: this code is to makesure other device get the same message
  // due to server also send message to sender, its to makesure
  // message is unique, thus we need to check if added message is unique
  if (!isMessageUnique(groups, message)) {
    let newGroup = updateMessageGroup(groups, message);
    let msg_group = {
      groups: newGroup,
      nextGroups,
      count,
      hasNext,
      lastMessageUnixTime,
    };

    return msg_group;
  }

  let lastGroup = groups.pop();
  if (!lastGroup) {
    return {
      groups: [
        {
          date: date,
          user_messages: [
            {
              messages: [message],
              user: {
                id: message.sender_id,
                fullname: message.sender_name,
              },
            },
          ],
        },
      ],
      count: 0,
    };
  }

  let isMessageAdded = false;
  if (date === lastGroup.date) {
    const { user_messages } = lastGroup;
    let lastMessageIndex = user_messages.length - 1;
    let lastMessage = user_messages[lastMessageIndex];

    if (lastMessage.sender_id === message.sender_id) {
      lastGroup.user_messages[lastMessageIndex].messages.push(message);
      isMessageAdded = true;
    }
  }

  if (isMessageAdded === false) {
    lastGroup.user_messages.push({
      messages: [message],
      user: {
        id: message.sender_id,
        fullname: message.sender_name,
      },
    });
  }

  groups.push(lastGroup);
  let msg_group = {
    groups,
    nextGroups,
    count,
    hasNext,
    lastMessageUnixTime,
  };

  return msg_group;
};

export const addSystemMessage = (state, message) => {
  const { messages } = state;
  const { count, groups, hasNext, nextGroups, lastMessageUnixTime } = messages;

  const date = moment(message.created_at).format("DD MMM YYYY");
  if (groups.length < 1) {
    return {
      groups: [
        {
          date: date,
          system: message,
        },
      ],
      count: 0,
    };
  }

  groups.push({
    date: date,
    system: message,
  });

  let msg_group = {
    groups,
    nextGroups,
    count,
    hasNext,
    lastMessageUnixTime,
  };

  return msg_group;
};

export const receiveMessageFromAI = (state, message) => {
  const { botIsTyping } = state;
  if (message.generated_by_ai) {
    return false;
  }

  return botIsTyping;
};

export const handleBotIsTyping = (state, payload) => {
  const { conversationId } = state;
  if (conversationId === payload.conversation_id) {
    return true;
  }
  return false;
};

export const removesendMessageFailedIds = (state, external_id) => {
  // eslint-disable-next-line no-use-before-define
  let sendMessageFailedIds = state.sendMessageFailedIds;
  if (!sendMessageFailedIds || sendMessageFailedIds === "") {
    return sendMessageFailedIds;
  }

  let split = sendMessageFailedIds.split(",");
  split.filter((id) => id !== external_id);

  return split.join(",");
};
