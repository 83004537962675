import * as React from 'react';

const buttonContainerStyles = {
  display: 'inline-flex',
  gap: '12px',
  padding: '2px',
  cursor: 'pointer',
  marginTop: '10px',
};

export const Step = ({
  stepContent,
  statusColor,
  statusCircleSize,
  onClickHandler,
}) => {
  const circleStyles = {
    borderRadius: statusCircleSize ?? 16,
    width: statusCircleSize ?? 16,
    height: statusCircleSize ?? 16,
    border: '2px solid #E1E1E1',
    background: statusColor ?? 'white',
  };

  const keyDownHandler = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      onClickHandler?.();
    }
  };

  return (
    <div
      tabIndex={0}
      onClick={onClickHandler}
      onKeyDown={keyDownHandler}
      role="button"
      style={{ ...buttonContainerStyles }}
    >
      <div>
        <div style={circleStyles} />
      </div>
      <div style={{ paddingBottom: 2 }}>{stepContent()}</div>
    </div>
  );
};
