import { takeEvery, put, call, select } from "redux-saga/effects"

// Chat Redux States
import {
  GET_CONVERSATION,
} from "./actionTypes"
import * as actions from "./actions"
import * as api from './api'

function* onGetConversationDetail({ payload: { id } }) {
  try {
    const response = yield call(api.getConversation, id)
    console.log('onGetConversationDetail detail', response)
    yield put(actions.getConversationSuccessAction(response))
  } catch (error) {
    yield put(actions.getConversationFailedAction(error))
  }
}

function* conversationSaga() {
  yield takeEvery(GET_CONVERSATION, onGetConversationDetail)
}

export default conversationSaga
