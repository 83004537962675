import React from "react";

const ChatLoaderWrapper = ({ isLoading }) => {
    if (!isLoading) return
    return (
        <div className="chat-loader-page">
            <div className="chat-loader-wrapper"></div>
            <p>loading...</p>
        </div>
    )
}

export default ChatLoaderWrapper