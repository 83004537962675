import React, { useState } from 'react'
import { Input } from 'reactstrap'

const SelectPlatformInput = ({ value, onChange, platforms, isLoading }) => {
    return (
        <div>
            <Input value={value} disabled={isLoading}
                onChange={(e) => onChange(e.target.value)}
                type='select'>
                <option value={0}>
                    All Platform
                </option>
                {platforms.map((pl, i) => (
                    <option value={pl.id} key={i}>
                        {pl.platform_name.toLocaleUpperCase()}
                    </option>
                ))}
            </Input>

        </div>
    )
}

export default SelectPlatformInput
