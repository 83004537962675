import {
  GET_CONVERSATION,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAILED,
  GET_CONVERSATION_INFO,
  GET_CONVERSATION_INFO_SUCCESS,
  GET_CONVERSATION_INFO_FAILED,
} from './actionTypes';
import { WEBSOCKET_BOT_EXIT_CONVERSATION } from '../websocket/actionTypes';

const INIT_STATE = {
  information: {},
  error: null,
  isLoading: false,
  isLoadingGetInfo: false,
};

const ConversationInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONVERSATION_INFO:
      return {
        ...state,
        isLoadingGetInfo: true,
      };

    case GET_CONVERSATION_INFO_SUCCESS:
      return {
        isLoadingGetInfo: false,
        information: action.payload,
        error: null,
      };
    case GET_CONVERSATION_INFO_FAILED:
      return {
        ...state,
        isLoadingGetInfo: false,
        error: action.payload,
      };
  }
  return state;
};

export default ConversationInfo;
