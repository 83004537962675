import * as React from 'react';

const separatorStyles = {
  height: '2vh',
  width: 2,
  border: '1px solid #E1E1E1',
  background: '#E1E1E1',
};

export const Separator = ({ height }) => {
  return <div style={{ ...separatorStyles, height: height ?? '20px' }} />;
};
