import React, { useState } from 'react'
import { connect } from 'react-redux';
import { selectMessage } from 'store/chat/actions'
import moment from 'moment';

const today = new Date()

const parseDate = (date) => {
  let dt = moment(date)

  if (dt.isSameOrAfter(today, 'day')) {
    return dt.format("hh:mm")
  }

  return dt.format("hh:mm DD MMM")
}

const MessageBubbleFooter = ({ message, selectMessage }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const divId = `Tooltip${message.id}`
  return (
    <div className='mb-2 mt-1 bubble-footer' style={{ 'display': 'block', 'width': '100%' }}>
      <span className='font-size-10 footer-information'>
        {/* {message.is_only_send === true &&
          <i className='fa fa-check' />
        }
        {!message.is_only_send &&
          <i className='fa fa-check-double' />
        } */}
        <span className='date'>
          {parseDate(message.created_at)}
        </span>
      </span>
    </div>
  )
}

export default connect(
  null,
  {
    selectMessage,
  }
)(MessageBubbleFooter)
