import { TypingIndicator } from "@chatscope/chat-ui-kit-react";
import React from "react";
import { connect } from "react-redux";

const ChatTypingIndicator = ({ addMessageRequested, botIsTyping }) => {
  if (botIsTyping) {
    return <TypingIndicator content="Risa AI sedang mengetik.." />;
  }

  return null;
};

const mapStateToProps = ({ chat }) => ({
  addMessageRequested: chat.addMessageRequested,
  botIsTyping: chat.botIsTyping,
});

export default connect(mapStateToProps, {})(ChatTypingIndicator);
