import * as React from "react"

const ChevronDown = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 24 24"
        {...props}
    >
        <path d="m7.7 12.2 4.3 4.2 4-3.9c2.2-2.1 3.7-4.1 3.5-4.4-.3-.2-2.1 1.1-4 2.9L12 14.4l-3.3-3.2C6.9 9.4 5 8 4.5 8s.9 1.9 3.2 4.2z" />
    </svg>
)
export default ChevronDown
