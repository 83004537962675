import React, { useState } from "react";

const AlertInfo = ({ text }) => {
    const [isClosed, setIsClosed] = useState(false)

    return <div className={isClosed ? "alert-info hide" : "alert-info"}>
        <i className="fa fa-window-close" onClick={() => setIsClosed(true)}></i>
        {text}
    </div>
}

export default AlertInfo