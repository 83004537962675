import * as React from 'react';

const BackButtonIcon = (props) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7426 16.4994H30V19.4994H11.7426L19.7886 27.5452L17.6673 29.6665L6 17.9994L17.6673 6.33203L19.7886 8.45335L11.7426 16.4994Z"
      fill="#181F2A"
    />
  </svg>
);
export default BackButtonIcon;
