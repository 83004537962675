import {
    GET_CONVERSATION,
    GET_CONVERSATION_SUCCESS,
    GET_CONVERSATION_FAILED,
} from "./actionTypes";


export const getConversationAction = (conversationId) => ({
    type: GET_CONVERSATION,
    payload: { id: conversationId }
})

export const getConversationSuccessAction = (conversation) => ({
    type: GET_CONVERSATION_SUCCESS,
    payload: conversation
})

export const getConversationFailedAction = (error) => ({
    type: GET_CONVERSATION_FAILED,
    payload: error
})

