import React from 'react'
import {
  Message,
} from "@chatscope/chat-ui-kit-react";
import MessageBubbleFooter from './MessageBubbleFooter';
import { API_URL } from 'network/http/api';
import Image from 'components/Image';

const MessageBubbleImage = ({ sender, message, style }) => (
  <Message key={message.external_id}
    style={{
      ...style,
      border: '1px solid',
      borderColor: message.is_rejected ? 'red' : 'transparent',
    }}
    className={'message-bubble-image'}
    model={{
      message: message.message,
      sender: sender.fullname,
      position: "normal"
    }}
  >
    <Message.CustomContent className='image-content'>
      <Image src={message.message} />
    </Message.CustomContent>
    <Message.Footer
    >
      <MessageBubbleFooter
        message={message} />
    </Message.Footer>
  </Message>
)

export default MessageBubbleImage
