import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';
import { notificationRequestAction } from 'store/notification/actions';
const audio = new Audio(process.env.REACT_APP_API_URL + "/static/notification-bubble.mp3");

const Notification = ({ notification, notificationRequest }) => {
  const playNotificationSound = () => {
    try {
      audio.play();
    } catch (err) {
      console.error("play notification sound error", err)
    }
  };

  useEffect(() => {
    if (
      !notification ||
      notification.body === '' ||
      notification?.type === ''
    ) {
      return;
    }

    switch (notification?.type) {
      case 'chat':
        if (notification.body.is_question) {
          break
        }
        // notifyChat();
        playNotificationSound()
        break;
      case 'error':
        toast.error(notification.body);
        break;
      case 'success':
        toast.success(notification.body);
        break;
      default:
        break;
    }

    if (notification.withSound) playNotificationSound();
  }, [notification]);

  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      toastOptions={{
        // Define default options
        duration: 5000,
      }}
    />
  );
};

const mapStateToProps = ({ Notification }) => ({
  notification: Notification,
});

export default connect(mapStateToProps, {
  notificationRequest: notificationRequestAction,
})(Notification);
