// Import scss
import './App.css';
import './assets/scss/app.scss';
import 'react-loading-skeleton/dist/skeleton.css'

import Layout from 'pages/Layout';
import PromptAPIKey from 'components/PromptAPIKey';
import Notification from 'components/Notification';
import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1M',
    MM: '%dM',
    y: '1Y',
    yy: '%dY'
  }
})

function App() {
  return (
    <div className="App">
      <Notification />
      <Layout />
      <PromptAPIKey />
    </div>
  );
}

export default App;
