const EndChatConfirmation = ({
  setIsOpenEndChat,
  handleOnClose,
  isLoading,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div className="end-chat-container">
        <p className="end-chat-container-title">Konfirmasi Mengakhiri Chat</p>
        <p className="end-chat-container-desc">
          Apakah Anda yakin ingin mengakhiri percakapan ini?
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            width: '100%',
            height: '32px',
            justifyContent: 'center',
          }}
        >
          <button
            disabled={isLoading}
            onClick={() => setIsOpenEndChat(false)}
            className="close"
            style={{
              height: '100%',
            }}
          >
            Tidak
          </button>
          <button
            disabled={isLoading}
            onClick={handleOnClose}
            className="close-primary"
            style={{
              height: '100%',
            }}
          >
            Ya
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndChatConfirmation;
