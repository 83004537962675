import { post } from 'network/http/api';
import React, { useState } from 'react';
import EndChatConfirmation from 'components/EndChatConfirmation';

const CloseConversation = ({ closeTray }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <button disabled={isLoading} onClick={closeTray} className="close">
        {!isLoading ? 'Akhiri Chat' : 'Loading...'}
      </button>
    </div>
  );
};

export default CloseConversation;
