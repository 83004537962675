import * as React from 'react';
const InactiveStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="none"
    viewBox="0 0 41 40"
    {...props}
  >
    <path
      d="M20.5009 30.433L8.74517 37.0133L11.3707 23.7995L1.47974 14.6527L14.8581 13.0664L20.5009 0.833008L26.1436 13.0664L39.5219 14.6527L29.6311 23.7995L32.2566 37.0133L20.5009 30.433ZM20.5009 26.613L27.5788 30.5748L25.9979 22.6192L31.9531 17.112L23.8983 16.1569L20.5009 8.79138L17.1035 16.1569L9.0486 17.112L15.0038 22.6192L13.423 30.5748L20.5009 26.613Z"
      fill="#F1D021"
    />
  </svg>
);
export default InactiveStar;
