const { get } = require('network/http/api');

export const fetchConversation = (
  platform,
  status = 'active,closed',
  per_page = 15,
  page = 1
) => {
  let query = `status=${status}&page=${page}&per_page=${per_page}`;
  if (platform && platform !== '') {
    query = `platforms=${platform}&${query}`;
  }
  return get(`/conversation/list?${query}`);
};
