import React, { useEffect, useCallback, useState } from 'react';
import {
  Avatar,
  Conversation,
  Sidebar,
  ConversationList,
} from '@chatscope/chat-ui-kit-react';
import { connect } from 'react-redux';
import { find } from 'lodash';

// action reducer
import { fetchConversationAction, getConversationAction } from 'store/actions';
import SelectPlatformInput from './SelectPlatform';
import moment from 'moment';

const ChatLeftSidebarUI = ({
  conversations,
  isFetching,
  totalConversation,
  users,
  fetchConversationAction,
  getConversationAction,
  conversationDetail,
  platforms,
}) => {
  // set state
  const [page, setPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(isFetching);
  const [selectedPlatform, setSelectedPlatform] = useState(0);
  const [activeFilter, setActiveFilter] = useState(0);

  useEffect(() => {
    fetchConversationAction();
  }, []);

  useEffect(() => {
    setIsLoadMore(isFetching);
  }, [isFetching]);

  const handleOnClick = (conv) => {
    getConversationAction(conv.id);
  };

  const handleLoadMore = useCallback(() => {
    if (isLoadMore || isFetching) {
      return;
    }

    if (totalConversation <= conversations.length) {
      return;
    }

    setIsLoadMore(true);
    let newPage = page;
    newPage += 1;
    setPage(newPage);
    fetchConversationAction(newPage, selectedPlatform);
  });

  const handleOnSelectFilter = (value) => {
    setActiveFilter(value);
  };

  return (
    <Sidebar position="left" scrollable={false}>
      <div className="p-2 mt-1 mb-1">
        <SelectPlatformInput
          value={selectedPlatform}
          onChange={(e) => setSelectedPlatform(e)}
          platforms={platforms}
          isLoading={isFetching}
        />
        <div className="filter">
          <div
            onClick={() => handleOnSelectFilter(0)}
            className={activeFilter !== 1 ? 'active' : ''}
          >
            Semua Pesan
          </div>
          <div
            onClick={() => handleOnSelectFilter(1)}
            className={activeFilter === 1 ? 'active' : ''}
          >
            Admin Takeover
          </div>
        </div>
      </div>
      <ConversationList scrollable loadingMore={isLoadMore}>
        {conversations?.map((conv) => {
          const user = find(users, { id: conv.user_creator_id });
          const platform = find(platforms, { id: conv.platform_id });

          let className = '';
          let fullname = user.fullname;

          if ((conv.status = 'closed')) {
            className = 'closed';
            fullname = `${fullname} (Close)`;
          }

          if (conv.is_human_takeover) {
            className += ' is_human_takeover';
          }

          if (activeFilter === 1 && !conv.is_human_takeover) {
            return null;
          }

          return (
            <Conversation
              onClick={() => handleOnClick(conv)}
              key={conv.id}
              className={className}
              name={fullname}
              lastActivityTime={moment(conv.last_message_date).fromNow()}
              active={conversationDetail.id === conv.id}
              lastSenderName={
                conv.last_message_text ? conv.last_message_text : ''
              }
              info={conv.last_message_text ? conv.last_message_text : ''}
            >
              <Avatar
                status="available"
                src={
                  process.env.PUBLIC_URL + `/images/${platform.name}-logo.png`
                }
              />
            </Conversation>
          );
        })}
      </ConversationList>
    </Sidebar>
  );
};

const mapStateToProps = ({ Conversations, ConversationDetail }) => {
  const { conversations, users, total_conversation, platforms } =
    Conversations.data;

  return {
    conversations: conversations,
    users: users,
    totalConversation: total_conversation,
    platforms: platforms,
    isFetching: Conversations.isLoading,
    conversationDetail: ConversationDetail.conversation,
  };
};

export default connect(mapStateToProps, {
  fetchConversationAction,
  getConversationAction,
})(ChatLeftSidebarUI);
