import { useState } from 'react';
import ConversationMobileList from './MobileVersion/ConversationMobileList';
import { connect, useDispatch } from 'react-redux';
import { fetchConversationAction, getConversationAction } from 'store/actions';
import ConversationUIKit from './MobileVersion/ConversationUIKit';

const MobileView = ({
  conversationDetail,
  addMessage,
  messages,
  getMessages,
  handleOnAddMessage,
  isLoadingPage,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      {Object.keys(conversationDetail).length > 0 ? (
        <>
          <ConversationUIKit
            disableSidebar={true}
            isQuestion={true}
            disableHeader={false}
            isLoading={isLoadingPage}
            messages={messages}
            onAddMessage={handleOnAddMessage}
            onGetMessages={getMessages}
            conversation={conversationDetail}
            placeholder={
              conversationDetail.is_human_takeover
                ? 'Tulis jawaban Anda di sini...'
                : 'Chat dijawab oleh RISA AI secara otomatis'
            }
            disableInput={
              conversationDetail.status === 'closed' ||
              !conversationDetail.is_human_takeover
            }
          />
        </>
      ) : (
        <ConversationMobileList />
      )}
    </div>
  );
};

const mapStateToProps = ({
  Conversations,
  ConversationDetail,
  chat,
  WebSocket,
}) => {
  const { conversations, users, total_conversation, platforms } =
    Conversations.data;

  return {
    conversations: conversations,
    users: users,
    totalConversation: total_conversation,
    platforms: platforms,
    chats: chat.chats,
    messages: chat.messages,
    isFetching: Conversations.isLoading,
    conversationDetail: ConversationDetail.conversation,
    selectedPlatforms: Conversations.selectedPlatforms,
    webSocketConnected: WebSocket.status === 'connected',
    botIsTyping: chat.botIsTyping,
  };
};

export default connect(mapStateToProps, {
  fetchConversationAction,
  getConversationAction,
})(MobileView);
