// import { handleNewPostEventDebounced } from 'store/websocket/actions/handler_post';
import { addMessageSuccess, updateMessageReceived } from 'store/chat/actions';
import { getConversationAction } from 'store/conversation/actions';
import { notificationChatRequestAction } from 'store/actions';
import {
  actionBotAnswerError,
  actionBotExitConversation,
  actionBotIsTyping,
} from './actions';
import { WEBSOCKET_SUCCESS } from './actionTypes';
import { fetchConversationAction } from 'store/conversations/actions';
import { clearMessage } from 'store/actions';
import Conversations from 'store/conversations/reducer';

// LIST WS COMMANDS NAME
const WebSocketEvent = {
  ChannelCreated: 'channel_created',
  NewMessage: 'new_message',
  UpdateMessage: 'update_message',
  BotIsTyping: 'bot_is_typing',
  BotAnswerError: 'bot_answer_error',
  Authenticated: 'authentication_challenge',
  BotExitConversation: 'bot_exit_conversation',
  UpdateConversation: 'update_conversation',
  NewConversation: 'new_conversation',
  FloatingSpeechEmbed: 'floating_speech_embed',
};

export function handleEvent(msg, dispatch) {
  console.log('message', msg);
  switch (msg.command) {
    case WebSocketEvent.Authenticated:
      dispatch({
        type: WEBSOCKET_SUCCESS,
        timestamp: Date.now(),
      });
      break;
    case WebSocketEvent.NewMessage:
      dispatch(addMessageSuccess(msg.data));
      dispatch(notificationChatRequestAction(msg.data));
      dispatch(
        fetchConversationAction(
          Conversations.selectedPlatforms?.join(','),
          undefined,
          1
        )
      );
      break;

    case WebSocketEvent.UpdateMessage:
      dispatch(updateMessageReceived(msg.data));
      break;

    case WebSocketEvent.ChannelCreated:
      console.log('ws channel created');
      break;

    case WebSocketEvent.BotExitConversation:
      dispatch(clearMessage());
      dispatch(actionBotExitConversation(msg.data));
      break;

    case WebSocketEvent.BotIsTyping:
      dispatch(actionBotIsTyping(msg.data));
      break;

    case WebSocketEvent.BotAnswerError:
      dispatch(actionBotAnswerError(msg.data));
      break;

    case WebSocketEvent.UpdateConversation:
      dispatch(getConversationAction(msg.data.id));
      break;
    case WebSocketEvent.NewConversation:
      dispatch(
        fetchConversationAction(
          Conversations.selectedPlatforms?.join(','),
          undefined,
          1
        )
      );
      break;
    default:
  }
}
