export const SELECTED_CONVERSATION = "SELECTED_CONVERSATION"

/* GROUPS */
export const GET_GROUPS = "GET_GROUPS"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL"

/* MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES"
export const RESET_MESSAGES_STATE = "RESET_MESSAGES_STATE"
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS"
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL"
export const GET_MESSAGE_NEXT_SUCCESS = 'GET_MESSAGE_NEXT_SUCCESS'
export const SELECT_MESSAGE = 'SELECT_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'

/* MESSAGES FROM WEBSOCKET */
export const UPDATE_MESSAGE_RECEIVED = "UPDATE_MESSAGE_RECEIVED"

export const POST_ADD_MESSAGE = "POST_ADD_MESSAGE"
export const POST_ADD_MESSAGE_SUCCESS = "POST_ADD_MESSAGE_SUCCESS"
export const POST_ADD_MESSAGE_FAIL = "POST_ADD_MESSAGE_FAIL"

/* WEBSOCKET */
export const WEBSOCKET_CONNECTED_SUCCESS = "WEBSOCKET_CONNECTED_SUCCESS"
export const WEBSOCKET_CONNECTED_FAILED = "WEBSOCKET_CONNECTED_FAILED"
export const WEBSOCKET_RECONNECT_REQUEST = "WEBSOCKET_RECONNECT_REQUEST"
export const WEBSOCKET_RECONNECT_SUCCESS = "WEBSOCKET_RECONNECT_SUCCESS"
export const WEBSOCKET_RECONNECT_FAILED = "WEBSOCKET_RECONNECT_FAILED"

// TYPING BOT
export const TYPING_BOT_REQUEST = "TYPING_BOT_REQUEST"
