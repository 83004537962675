import { NOTIFICATION_REQUEST } from "./actionTypes";

export const notificationErrorRequestAction = (msg) => ({
    type: NOTIFICATION_REQUEST,
    payload: {
        body: msg,
        type: 'error',
    }
})


export const notificationChatRequestAction = (msg) => {
    return {
        type: NOTIFICATION_REQUEST,
        payload: {
            body: msg,
            type: 'chat',
        }
    }
}



export const notificationSuccessRequestAction = (msg) => ({
    type: NOTIFICATION_REQUEST,
    payload: {
        body: msg,
        type: 'success',
    }
})

export const notificationRequestAction = (payload) => ({
    type: NOTIFICATION_REQUEST,
    payload: payload
})