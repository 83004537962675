import * as React from 'react';
const CloseSidebar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#29CCB1" />
    <path
      d="M12 11.5H25V13.5H12V11.5ZM7 11H10V14H7V11ZM7 18H10V21H7V18ZM12 18.5H25V20.5H12V18.5Z"
      fill="#29CCB1"
    />
  </svg>
);
export default CloseSidebar;
