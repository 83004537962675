import * as React from 'react';
const OpenSidebar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="#EAFAF7" />
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#29CCB1" />
    <path
      d="M25.7956 12.53C25.7956 11.98 25.3456 11.53 24.7956 11.53H17.7956C17.2456 11.53 16.7956 11.98 16.7956 12.53C16.7956 13.08 17.2456 13.53 17.7956 13.53H24.7956C25.3456 13.53 25.7956 13.08 25.7956 12.53ZM16.7956 20.53C16.7956 21.08 17.2456 21.53 17.7956 21.53H24.7956C25.3456 21.53 25.7956 21.08 25.7956 20.53C25.7956 19.98 25.3456 19.53 24.7956 19.53H17.7956C17.2456 19.53 16.7956 19.98 16.7956 20.53ZM14.2656 9.16C14.6556 9.55 14.6556 10.18 14.2656 10.57L10.0356 14.82C9.64562 15.21 9.01563 15.21 8.61563 14.82L6.49562 12.69C6.10563 12.3 6.10563 11.67 6.49562 11.28C6.88563 10.89 7.51563 10.89 7.90563 11.28L9.32563 12.7L12.8656 9.16C13.2456 8.78 13.8856 8.78 14.2656 9.16ZM14.2756 17.17C14.6656 17.56 14.6656 18.19 14.2756 18.58L10.0456 22.83C9.65562 23.22 9.02562 23.22 8.62562 22.83L6.49562 20.69C6.10563 20.3 6.10563 19.67 6.49562 19.28C6.88563 18.89 7.51563 18.89 7.90563 19.28L9.32563 20.7L12.8656 17.16C13.2456 16.78 13.8856 16.78 14.2756 17.17Z"
      fill="#29CCB1"
    />
  </svg>
);
export default OpenSidebar;
