import {
  GET_CONVERSATION,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAILED,
} from './actionTypes';
import { WEBSOCKET_BOT_EXIT_CONVERSATION } from '../websocket/actionTypes';
import { CLEAR_MESSAGE } from 'store/chat/actionTypes';

const INIT_STATE = {
  conversation: {},
  review: {},
  error: null,
  isLoading: false,
};

const Conversation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONVERSATION:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CONVERSATION_SUCCESS:
      return {
        isLoading: false,
        conversation: action.payload.conversation,
        user: action.payload.user,
        review: action.payload.review,
        platform: action.payload.platform,
        error: null,
      };
    case GET_CONVERSATION_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case WEBSOCKET_BOT_EXIT_CONVERSATION:
    case CLEAR_MESSAGE:
      return {
        conversation: {},
        review: {},
        error: null,
        isLoading: false,
      }
  }
  return state;
};

export default Conversation;
