import { JWT_TOKEN_KEY } from 'network/http/jwt-token-access';
import React, { useEffect, useState } from 'react';
import { getCookie, getQueryFromURL, getUserToken } from 'components/helper';

// Component
import LiveChatEmbed from 'pages/LiveChatEmbed';
import OnboardingEmbed from 'pages/Onboarding';
import LandingChat from 'pages/LandingChat';
import TestScenario from './TestScenario';
import OmniChannel from './OmniChannel';

// Setup the router
const routers = [
  {
    path: '/chat',
    element: <LiveChatEmbed />,
  },
  {
    path: '/',
    element: <LiveChatEmbed />,
  },
  {
    path: '/test-scenario',
    element: <TestScenario />,
  },
  {
    path: '/landing',
    element: <LandingChat />,
  },
  {
    path: '/onboarding',
    element: <OnboardingEmbed />,
  },
  {
    path: '/omnichannel',
    element: <OmniChannel />,
  },
];

const Layout = () => {
  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => {
    let client_id,
      secret = '';
    if (window.risa_account) {
      client_id = window.risa_account.client_id;
      secret = window.risa_account.secret;
    } else {
      client_id = getQueryFromURL.client_id;
      secret = getQueryFromURL.secret;
    }
    let authorization = btoa(`${secret}:${client_id}`);
    document.cookie = `${JWT_TOKEN_KEY}=${authorization}`;
  }, []);

  useEffect(() => {
    setUserCookie();
    if (!window.risa_account) {
      const { pathname } = window.location;
      if (pathname !== currentLocation) {
        setCurrentLocation(pathname);
        return;
      }
    }

    if (!window.risa_account || !window.risa_account.location) {
      return;
    }
    let risa_location = window.risa_account.location;
    if (currentLocation === risa_location) {
      return;
    }

    console.log('risa location', risa_location);
    setCurrentLocation(risa_location);
  }, []);

  const setUserCookie = () => {
    if (!window.risa_account || !window.risa_account.authorization) {
      return;
    }
    document.cookie = getUserToken();
  };

  const renderRouters = () => {
    if (!currentLocation) return <LiveChatEmbed />;

    return routers.map((router, i) => {
      if (router.path === currentLocation) {
        return <div key={i}>{router.element}</div>;
      }
      return null;
    });
  };

  return <div id="risa-layout-chat">{renderRouters()}</div>;
};

export default Layout;
