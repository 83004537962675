import React, { useEffect, useRef } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  Message,
  MessageGroup,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import RisaPng from "assets/images/avatar-risa.png";
import { get, post } from "network/http/api";
import { getUserToken } from "components/helper";
import AlertInfo from "components/AlertInfo";
import { useState } from "react";
import CustomDropdown from "components/Dropdown";

const OnBoarding = () => {
  let inputRef = useRef();

  const [userName, setUserName] = useState("");
  const [conversationNew, setConversationNew] = useState([]);
  const [renderedMessages, setRenderedMessages] = useState([
    {
      type: "incoming",
      message: [],
    },
  ]);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [questionAnswer, setQuestionAnswer] = useState();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedChangeOptions, setSelectedChangeOptions] = useState({});
  const [lastMessage, setLastMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState(null);
  const [isSendMessage, setIsSendMessage] = useState(false);
  const [disableInput, setDisableInput] = useState(true)

  useEffect(() => {
    const fetchPredefined = () => {
      setIsLoading(true);
      get("/onboarding/predefined", {
        headers: {
          Authorization: getUserToken(),
        },
      })
        .then((resp) => {
          const { messages, exists } = resp
          if (exists) {
            window.location.replace("/dashboard")
            return
          }

          setConversationNew(messages);
          const lastObject = messages[messages.length - 1];
          setLastMessage(lastObject);
          setIsSendMessage(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(err.detail);
          setIsLoading(false);
        });
    };

    fetchPredefined();
  }, []);

  useEffect(() => {
    if (!isSendMessage) {
      return;
    }

    const renderNextMessage = () => {
      if (currentMessageIndex < conversationNew.length) {
        let currentMessage = conversationNew[currentMessageIndex];

        if (userName !== "") {
          currentMessage.message = currentMessage.message.replace(
            "{name}",
            userName
          );
        }

        let lastRenderedMessage = renderedMessages[renderedMessages.length - 1];
        setQuestionAnswer(lastRenderedMessage);
        let newMessage = {};

        if (lastRenderedMessage.type === "incoming") {
          renderedMessages[renderedMessages.length - 1].message.push(
            currentMessage
          );
          setRenderedMessages(renderedMessages);
        } else {
          newMessage = {
            type: "incoming",
            message: [currentMessage],
          };
          renderedMessages.push(newMessage);
          setRenderedMessages(renderedMessages);
        }

        if (currentMessage.disable_input || currentMessage.type === 'options') {
          setDisableInput(true)
        } else {
          setDisableInput(false)
        }

        // update current message
        setCurrentMessage(currentMessage);
        setCurrentMessageIndex((prevIndex) => prevIndex + 1);

        if (!currentMessage.disable_input) {
          inputRef.current.focus();
          setIsSendMessage(false);
          return;
        }
      }
    };

    const messageInterval = setInterval(renderNextMessage, 1000);

    if (currentMessageIndex === conversationNew.length) {
      clearInterval(messageInterval);
    }

    return () => {
      clearInterval(messageInterval);
    };
  }, [
    currentMessageIndex,
    conversationNew,
    isSendMessage,
    renderedMessages,
    userName,
  ]);

  const handleOnSubmit = (paramsText) => {
    const question = questionAnswer.message[questionAnswer.message.length - 1];

    if (question.variable_name === "name") {
      setUserName(paramsText);
    }

    const message = {
      type: "outgoing",
      message: [
        {
          message: paramsText,
          is_question: false,
          sender_id: "user_id",
          options: [],
          type: question.type,
          invalid_answer: "",
          disable_input: false,
          variable_name: question.variable_name,
        },
      ],
    };
    const payload = {
      answer: paramsText,
      question: question.message,
    };

    post("/onboarding/validate-input", payload)
      .then((resp) => {
        setSelectedOptions({
          ...selectedOptions,
          [question.variable_name]: paramsText,
        });
        setRenderedMessages((prevMessages) => [...prevMessages, message]);
        setIsSendMessage(true);
      })
      .catch((err) => {
        setIsError(err.detail);
      });
  };

  const handleToggle = (index) => {
    if (openedDropdownIndex === index) {
      setOpenedDropdownIndex(null);
    } else {
      setOpenedDropdownIndex(index);
    }
  };

  const handleSelect = (option, variableName) => {
    setSelectedOptions({
      ...selectedOptions,
      [variableName]: option,
    });
    setOpenedDropdownIndex(null);
  };

  const handleSelectChange = (variableName) => {
    setSelectedChangeOptions({
      ...selectedChangeOptions,
      [variableName]: true,
    });
    setIsSendMessage(true);
  };

  const onSubmitOnBoarding = () => {
    const payload = selectedOptions;
    post("/onboarding/finish", payload, {
      headers: {
        Authorization: getUserToken(),
      },
    })
      .then((resp) => {
        window.location.replace("/dashboard?new-member=true");
      })
      .catch((err) => {
        setIsError(err.detail);
      });
  };

  return (
    <React.Fragment>
      <div className="onboarding">
        {isError !== "" && <AlertInfo text={isError} />}
        <div className="container">
          {isLoading ? (
            <span>Loading ...</span>
          ) : (
            <MainContainer>
              <ChatContainer>
                <MessageList>
                  {renderedMessages.map((item, i) => {
                    return (
                      <MessageGroup
                        key={i}
                        direction={item.type}
                        avatarPosition="tl"
                      >
                        {item.type === "incoming" && item.message.length && (
                          <Avatar src={RisaPng} name="Avatar" />
                        )}
                        <MessageGroup.Messages>
                          {item.message.map((items, idx) => {
                            if (items.type === "options") {
                              return (
                                <Message
                                  key={idx}
                                  style={{
                                    borderRadius: "0 .7em .7em .7em",
                                    border: "1px solid",
                                    borderColor: "transparent",
                                    marginBottom: "10px",
                                    overflow: "visible",
                                  }}
                                  className={"generated_by_ai"}
                                  model={{
                                    message: items.message,
                                    sender: "Risa Ai",
                                    position: "normal",
                                  }}
                                >
                                  <Message.CustomContent
                                    style={{ width: "100%" }}
                                  >
                                    <div className="onboarding-select">
                                      <CustomDropdown
                                        title={items.message}
                                        options={items.options}
                                        onSelect={(option) =>
                                          handleSelect(
                                            option,
                                            items.variable_name
                                          )
                                        }
                                        isOpen={openedDropdownIndex === idx}
                                        handleToggle={() => handleToggle(idx)}
                                      />
                                      {selectedOptions[items.variable_name] &&
                                        !selectedChangeOptions[
                                        items.variable_name
                                        ] && (
                                          <button
                                            disabled={
                                              !selectedOptions[
                                              items.variable_name
                                              ]
                                            }
                                            style={{
                                              backgroundColor: selectedOptions[
                                                items.variable_name
                                              ]
                                                ? "#24b39b"
                                                : "#AEAEAE",
                                            }}
                                            onClick={() =>
                                              handleSelectChange(
                                                items.variable_name
                                              )
                                            }
                                          >
                                            Pilih
                                          </button>
                                        )}
                                    </div>
                                  </Message.CustomContent>
                                </Message>
                              );
                            }

                            return (
                              <Message
                                key={idx}
                                style={{
                                  borderRadius: "0 .7em .7em .7em",
                                  border: "1px solid",
                                  borderColor: "transparent",
                                  marginBottom: "10px",
                                }}
                                className={"generated_by_ai"}
                                model={{
                                  message: items.message,
                                  sender: "Risa Ai",
                                  position: "normal",
                                }}
                              >
                                <Message.CustomContent
                                  style={{ width: "100%" }}
                                >
                                  <div className="onboarding-chat">
                                    <span>{items.message}</span>
                                    {lastMessage?.message ===
                                      items?.message && (
                                        <button
                                          onClick={() => onSubmitOnBoarding()}
                                        >
                                          Lanjut
                                        </button>
                                      )}
                                  </div>
                                </Message.CustomContent>
                              </Message>
                            );
                          })}
                        </MessageGroup.Messages>
                      </MessageGroup>
                    );
                  })}
                </MessageList>
                <MessageInput
                  ref={inputRef}
                  disabled={disableInput}
                  style={{ opacity: 1 }}
                  attachButton={false}
                  onSend={handleOnSubmit}
                  placeholder="Tulis jawabanmu disini"
                />
              </ChatContainer>
            </MainContainer>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnBoarding;
