import React, { Fragment, useEffect, useRef, useState } from 'react';
import RatingStar from './RatingStar';
import { clearMessage } from 'store/actions';
import { useDispatch } from 'react-redux';

const RatingForm = ({ handleSubmitRating }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSetRating = (value) => {
    setRating(value);
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    handleSubmitRating({
      rating: rating,
      comment: comment,
    })
      ?.then((resp) => {
        dispatch(clearMessage());
        setRating(0);
        setComment('');
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div className="end-chat-container">
        <p className="end-chat-container-title">Penilaian Anda Kami Terima!</p>
        <p className="end-chat-container-desc">
          Terima kasih telah memberikan penilaian.
        </p>

        <RatingStar
          isReadOnly={false}
          rating={rating}
          handleSetReview={handleSetRating}
        />

        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
            }}
          >
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <>
            {rating > 0 && (
              <textarea
                onChange={handleChangeComment}
                placeholder="Tulis Komentar"
                style={{
                  marginBottom: '8px',
                  minHeight: '100px',
                }}
              />
            )}

            {rating > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  width: '100%',
                  height: '32px',
                  justifyContent: 'center',
                }}
              >
                <button
                  disabled={isLoading}
                  onClick={handleSubmit}
                  className="close-primary"
                  style={{
                    height: '100%',
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RatingForm;
