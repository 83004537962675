import React from 'react';
import RatingStar from './RatingStar';

const RatingReview = ({ rating, comment }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div className="rating-review-container">
        <p className="rating-review-title">
          Penilaian dan Feedback untuk Admin
        </p>

        {rating > 0 && (
          <>
            <RatingStar isReadOnly rating={rating} />

            {rating < 4 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '8px',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <p className="regular-bold">Kritik dan Masukan :</p>
                <p className="regular">
                  {comment || 'Belum ada penilaian dari customer'}
                </p>
              </div>
            )}

            {rating > 3 && (
              <p className="regular">
                Anda mendapatkan penilaian sangat baik! Penilaian ini diberikan
                oleh pelanggan yang telah menyelesaikan sesi percakapan dengan
                Anda.{' '}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RatingReview;
