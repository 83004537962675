import moment from 'moment';

export const parseDate = (date) => {
  const today = new Date();
  let dt = moment(date);

  if (dt.isSameOrAfter(today, 'day')) {
    return dt.format('hh:mm');
  }

  return dt.format('hh:mm DD MMM');
};
