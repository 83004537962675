import {
  GET_CONVERSATION_INFO,
  GET_CONVERSATION_INFO_SUCCESS,
  GET_CONVERSATION_INFO_FAILED,
} from './actionTypes';

export const getConversationInfoAction = (conversationId) => ({
  type: GET_CONVERSATION_INFO,
  payload: { id: conversationId },
});

export const getConversationInfoSuccessAction = (information) => ({
  type: GET_CONVERSATION_INFO_SUCCESS,
  payload: information,
});

export const getConversationInfoFailedAction = (error) => ({
  type: GET_CONVERSATION_INFO_FAILED,
  payload: error,
});
