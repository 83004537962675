import { TypingIndicator } from "@chatscope/chat-ui-kit-react"
import React from "react"
import { connect } from "react-redux"

const ChatTypingIndicator = ({ addMessageRequested, botIsTyping }) => {
  if (botIsTyping) {
    return <TypingIndicator content="Risa AI sedang mengetik.." />
  }

  // if (addMessageRequested)
  //   return <TypingIndicator content="Send message ..." />

  return null
}



const mapStateToProps = ({ chat }) => ({
  addMessageRequested: chat.addMessageRequested,
  botIsTyping: chat.botIsTyping,
});


export default connect(
  mapStateToProps,
  {})(ChatTypingIndicator)
