import { takeEvery, put, call, select } from "redux-saga/effects"

// Chat Redux States
import {
  GET_MESSAGES,
  POST_ADD_MESSAGE,
} from "./actionTypes"
import * as actions from "../actions"
import * as api from './api'

function* onGetMessages({ payload: { roomId, lastMessageUnix } }) {
  try {
    const response = yield call(api.fetchConversationMessage, roomId, lastMessageUnix)
    if (lastMessageUnix === 0) {
      yield put(actions.getMessagesSuccess(response))
    } else {
      yield put(actions.getMessagesNextSuccess(response))
    }

  } catch (error) {
    console.error(error)
    yield put(actions.getMessagesFail(error))
  }
}

function* onAddMessage({ payload: { message } }) {
  try {
    const response = yield call(api.newMessage, message)

    yield put(actions.addMessageSuccess(response))
  } catch (error) {
    yield put(actions.addMessageFail(message, error))
  }
}

function* chatSaga() {
  yield takeEvery(GET_MESSAGES, onGetMessages)
  yield takeEvery(POST_ADD_MESSAGE, onAddMessage)
}

export default chatSaga
