import React, { useState } from "react";

const PromptAPIKey = () => {
    const [clientId, setClientId] = useState('')
    const [apiSecret, setApiSecret] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    useState(() => {

        if (window.risa_account) {
            return
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("client_id") != null && urlParams.get("secret") != null) {
            window.risa_account = {
                'client_id': urlParams.get("client_id"),
                'secret': urlParams.get("secret")
            }
            setIsOpen(false)
            return
        }

        setIsOpen(true)
    })

    const handleOnSave = () => {
        if (clientId === '') {
            window.prompt("Client Id is required")
            return
        }

        if (apiSecret === '') {
            window.prompt("Secret is required")
            return
        }

        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('client_id', clientId);
        urlParams.set('secret', apiSecret)

        window.location.search = urlParams;
        // window.risa_account = {
        //     'client_id': clientId,
        //     'secret': apiSecret
        // }
        setIsOpen(false)
        // window.location.reload()
    }

    if (!isOpen) {
        return null
    }

    return <div className="prompt-api">
        <div className="modal">
            <div className="modal-header">
                Masukan API Key Anda
            </div>
            <div className="model-body">
                <div className="input">
                    <p>Client Id</p>
                    <input value={clientId} onChange={(e) => { setClientId(e.target.value) }} />
                </div>
                <div className="input">
                    <p>API Secret</p>
                    <input value={apiSecret} onChange={(e) => { setApiSecret(e.target.value) }} />
                </div>
                <div className="button">
                    <button className="save" onClick={handleOnSave}>Save</button>
                </div>
            </div>
        </div>
    </div>
}

export default PromptAPIKey