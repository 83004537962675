import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { get, post, put } from 'network/http/api';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  ConversationHeader,
  MessageSeparator,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ChatTypingIndicator from './ChatTypingIndicator';
import { find } from 'lodash';
import { Button } from 'reactstrap';
// Styles
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import GroupMessage from './GroupMessage';
import Image from 'components/Image';
import ChatSidebar from './ChatSidebar';
import ConfirmationModal from './ConfirmationModal';
import InformationUserSidebar from './InformationUserSidebar';
import {
  notificationErrorRequestAction,
  selectedConversation,
} from 'store/actions';
import { useDispatch } from 'react-redux';
import OpenSidebar from 'components/Icon/OpenSidebar';
import CloseSidebar from 'components/Icon/CloseSidebar';
import RatingReview from 'components/RatingReview';

const ConversationUIKit = ({
  conversation,
  onGetMessages,
  onAddMessage,
  messages,
  selectedMessage,
  fetchingDetail,
  disableInput,
  hideInputBox,
  botGenerateAnswerError,
  isQuestion,
  disableHeader,
  isLoading,
  isRequested,
  placeholder = 'Tulis pertanyaan...',
  disableSidebar = true,
  user,
  platform,
  onGetProfileDetail,
  review,
}) => {
  let inputRef = useRef();
  const [loadedMessage, setLoadedMessage] = useState([]);
  const [messageGroups, setMessageGroups] = useState([]);
  const [conversationId, setConversationId] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastMessageUnix, setLastMessageUnix] = useState(0);
  const [isOpenEndChat, setIsOpenEndChat] = useState(false);
  const [loadingEndChat, setLoadingEndChat] = useState(false);
  const [isOpenInfoUser, setIsOpenInfoUser] = useState(false);
  const dispatch = useDispatch();

  const msgListRef = useRef();
  const scrollToBottom = () => {
    msgListRef.current.scrollToBottom('smooth');
  };

  useEffect(() => {
    const { groups, nextGroups } = messages;

    setConversationId(conversation.id);
    if (groups !== messageGroups) {
      setMessageGroups(groups);
    }

    setLoadedMessage(nextGroups);
  }, [conversation.id, messageGroups, messages]);

  // scroll to bottom when new message received
  useEffect(() => {
    scrollToBottom();
  }, [messageGroups]);

  useEffect(() => {
    if (!conversation.id) {
      return;
    }

    if (fetchingDetail) {
      return;
    }

    if (conversationId === conversation.id) {
      return;
    }

    setMessageGroups([]);
    setLoadedMessage([]);
    onGetMessages(conversation.id);
    setConversationId(conversation.id);
  }, [
    conversation,
    conversationId,
    fetchingDetail,
    onGetMessages,
    onGetProfileDetail,
  ]);

  useEffect(() => {
    if (loadingMore !== isRequested) {
      setLoadingMore(isRequested);
    }
  }, [isRequested, loadingMore]);

  const handleOnSubmit = (value) => {
    const message = {
      external_id: uuidv4(),
      conversation_id: conversation.id,
      is_question: false,
      message: value,
      type: 'text',
      createdAt: new Date(),
    };

    onAddMessage(message);
    scrollToBottom();
  };

  const onYReachStart = () => {
    const { count, groups, hasNext, lastMessageUnixTime } = messages;

    if (!conversation.id) {
      return;
    }

    if (loadingMore && !isRequested) {
      setLoadingMore(false);
    }

    if (!hasNext) {
      setLoadingMore(false);
      return;
    }

    if (loadingMore) {
      return;
    }

    if (!groups) {
      return;
    }

    if (lastMessageUnix === lastMessageUnixTime) {
      return;
    }

    /* fetch from API */
    setLoadingMore(true);
    onGetMessages(conversation.id, parseInt(lastMessageUnixTime));
    setLastMessageUnix(lastMessageUnixTime);
  };

  const renderMessageGroupTime = (time) => {
    var date = moment(time);
    var now = moment();

    if (now.diff(date, 'day') === 0) {
      return 'Hari Ini';
    } else if (now.diff(date, 'day') === 1 && date.isBefore(now)) {
      return 'Kemarin';
    } else {
      return moment(time).format('DD MMMM YYYY');
    }
  };

  const handleOnClose = () => {
    setLoadingEndChat(true);
    get(`/conversation/close/${conversationId}`)
      .then((resp) => {
        setIsOpenEndChat(false);
        setLoadingEndChat(false);
      })
      .catch((err) => {
        dispatch(notificationErrorRequestAction(err));
        setIsOpenEndChat(false);
        setLoadingEndChat(false);
      });
  };

  return (
    <>
      <MainContainer responsive>
        {!disableSidebar && <ChatSidebar />}
        <ChatContainer>
          {!disableHeader && (
            <ConversationHeader>
              <Avatar
                src={
                  platform?.platform_name !== 'web'
                    ? `${process.env.REACT_APP_PUBLIC_URL}/images/${platform?.platform_name}-logo.svg`
                    : `${process.env.REACT_APP_PUBLIC_URL}/images/web-icon.svg`
                }
              />
              <ConversationHeader.Content userName="Risa AI">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className="label">
                    {user?.fullname || conversation.title || 'Visitor'}
                  </div>

                  {conversation.is_human_takeover && (
                    <div className="value">
                      terakhir dibalas oleh{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {window.risa_account.admin_user?.fullname}
                      </span>{' '}
                      {conversation.last_message_date &&
                        `   pada{' '}` +
                          moment(conversation.last_message_date).format(
                            'HH:mm'
                          )}
                    </div>
                  )}
                </div>

                {isLoading && (
                  <div className="chat-loader">
                    <Image src="/reload-icon.svg" /> Sedang memuat chat...
                  </div>
                )}

                {conversation.status !== 'closed' && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                    }}
                  >
                    {!conversation.is_human_takeover &&
                      conversationId &&
                      conversationId !== 0 && (
                        <ConfirmationModal conversationId={conversationId} />
                      )}

                    {conversation.is_human_takeover &&
                      conversationId &&
                      conversationId !== 0 && (
                        <Button
                          disabled={isLoading}
                          onClick={() => setIsOpenEndChat(true)}
                          color="primary"
                          outline
                          style={{
                            width: 'fit-content',
                          }}
                        >
                          Selesaikan Percakapan
                        </Button>
                      )}

                    {conversationId && conversationId !== 0 && (
                      <>
                        {isOpenInfoUser ? (
                          <div
                            className="sidebar-button"
                            onClick={() => setIsOpenInfoUser(false)}
                          >
                            <OpenSidebar />
                          </div>
                        ) : (
                          <div
                            className="sidebar-button"
                            onClick={() => setIsOpenInfoUser(true)}
                          >
                            <CloseSidebar />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </ConversationHeader.Content>
            </ConversationHeader>
          )}
          <MessageList
            ref={msgListRef}
            // loadingMore={loadingMore}
            loading={isRequested || fetchingDetail}
            onYReachEnd={onYReachStart}
            typingIndicator={<ChatTypingIndicator />}
          >
            {/* LOAD OLDER MESSAGE */}
            {loadedMessage &&
              loadedMessage.map((group, idx) => {
                return (
                  <div key={`old-${idx}`}>
                    <GroupMessage
                      group={group}
                      scrollToBottom={scrollToBottom}
                      selectedMessage={selectedMessage}
                    />
                  </div>
                );
              })}

            {/* LOAD NEWER MESSAGE */}
            {messageGroups &&
              messageGroups.map((group, idx) => {
                return (
                  <div key={`new-${idx}`}>
                    <MessageSeparator content="Content from property">
                      <div className="group-time">
                        {renderMessageGroupTime(group.date)}
                      </div>
                    </MessageSeparator>
                    <GroupMessage
                      group={group}
                      scrollToBottom={scrollToBottom}
                      selectedMessage={selectedMessage}
                    />
                  </div>
                );
              })}

            {botGenerateAnswerError && (
              <MessageSeparator content="Content from property">
                <p style={{ color: 'red' }}>
                  Sorry AI failed to generate answer, please to try again.
                </p>
              </MessageSeparator>
            )}

            {isOpenEndChat && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div className="end-chat-container">
                  <p className="end-chat-container-title">
                    Apakah Anda yakin ingin menghentikan percakapan ini?
                  </p>
                  <p className="end-chat-container-desc">
                    Pastikan Anda telah menjawab pertanyaan pelanggan Anda dalam
                    menyelesaikan masalah
                  </p>
                  <div className="end-chat-container-actions">
                    <Button
                      onClick={() => setIsOpenEndChat(false)}
                      color="primary"
                      outline
                      style={{
                        width: 'fit-content',
                      }}
                    >
                      Batal
                    </Button>
                    <Button
                      disabled={loadingEndChat}
                      onClick={handleOnClose}
                      color="primary"
                      style={{
                        width: 'fit-content',
                      }}
                    >
                      Yakin, Hentikan
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {conversation.status === 'closed' && (
              <RatingReview
                rating={review?.rating}
                comment={review?.comment}
                isLoading={loadingEndChat}
              />
            )}
          </MessageList>

          <MessageInput
            style={{ opacity: hideInputBox ? 0 : 1 }}
            ref={inputRef}
            autoFocus={disableInput}
            disabled={disableInput}
            attachButton={false}
            onSend={handleOnSubmit}
            placeholder={placeholder}
          />
        </ChatContainer>
        {conversationId && conversationId !== 0 && isOpenInfoUser && (
          <InformationUserSidebar conversation={conversation} />
        )}
      </MainContainer>
    </>
  );
};

const mapStateToProps = ({
  chat,
  ConversationDetail,
  Conversations,
  UserProfile,
}) => {
  const { users, platforms } = Conversations.data;
  const user = find(users, {
    id: ConversationDetail.conversation.user_creator_id,
  });
  const platform = find(platforms, {
    id: ConversationDetail.conversation.platform_id,
  });

  return {
    botGenerateAnswerError: chat.botGenerateAnswerError,
    addMessageRequested: chat.addMessageRequested,
    conversation: ConversationDetail.conversation,
    review: ConversationDetail.review,
    user: user,
    platform,
    userProfile: UserProfile,
  };
};

export default connect(mapStateToProps, null)(ConversationUIKit);
