import {
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  RESET_MESSAGES_STATE,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,
  POST_ADD_MESSAGE,
  UPDATE_MESSAGE_RECEIVED,
  GET_MESSAGES,
  GET_MESSAGE_NEXT_SUCCESS,
  SELECTED_CONVERSATION,
  SELECT_MESSAGE,
  CLEAR_MESSAGE,
} from './actionTypes';
import {
  WEBSOCKET_BOT_ANSWER_ERROR,
  WEBSOCKET_BOT_EXIT_CONVERSATION,
  WEBSOCKET_BOT_IS_TYPING,
  WEBSOCKET_FLOATING_SPEECH_EMBED,
} from 'store/websocket/actionTypes';
import {
  addNewMessage,
  addSystemMessage,
  handleBotIsTyping,
  loadMoreMessageState,
  receiveMessageFromAI,
  updateMessageGroup,
  updateMessageState,
} from './reducer_utils';
import { v4 as uuidv4 } from 'uuid';
const INIT_STATE = {
  conversationId: 0,
  messages: {
    count: 0,
    groups: [],
    nextGroups: [],
    hasNext: false,
    lastMessageUnixTime: 0,
  },
  newMessagePayload: {},
  selectedMessage: null,
  botGenerateAnswerError: false,
  addMessageRequested: false,
  isRequested: false,
  botIsTyping: false,
  error: {},
  showFloatingSpeech: false,
};

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_MESSAGE:
      return {
        ...state,
        messages: {
          count: 0,
          groups: [],
          nextGroups: [],
          hasNext: false,
          lastMessageUnixTime: 0,
        },
        isRequested: false,
        botIsTyping: false,
      };
    case SELECTED_CONVERSATION:
      return {
        ...state,
        conversationId: action.payload,
      };
    case GET_MESSAGES:
      return {
        ...state,
        conversationId: action.payload.roomId,
        isRequested: true,
      };
    case RESET_MESSAGES_STATE:
      return {
        ...state,
        conversationId: 0,
        messages: {
          count: 0,
          groups: [],
          nextGroups: [],
          hasNext: false,
          lastMessageUnixTime: 0,
        },
        selectedMessage: null,
        error: {},
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      };

    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_MESSAGE_NEXT_SUCCESS:
      return {
        ...state,
        isRequested: false,
        messages: loadMoreMessageState(state, action.payload),
      };

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        isRequested: false,
        messages: {
          groups: action.payload.groups,
          nextGroups: [],
          count: action.payload.count,
          hasNext: action.payload.has_next,
          lastMessageUnixTime: action.payload.last_message_unix_time,
        },
      };

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        isRequested: false,
        error: action.payload,
      };
    case POST_ADD_MESSAGE:
      let { message } = action.payload;

      // send message
      message.is_only_send = true;

      return {
        ...state,
        messages: addNewMessage(state, message),
        addMessageRequested: true,
      };
    case POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: addNewMessage(state, action.payload),
        addMessageRequested: false,
        botGenerateAnswerError: false,
        botIsTyping: receiveMessageFromAI(state, action.payload),
      };

    case POST_ADD_MESSAGE_FAIL:
      let failed_message = action.payload.message;

      // send message
      failed_message.is_failed = true;
      return {
        ...state,
        error: action.payload,
        addMessageRequested: false,
        messages: addNewMessage(state, failed_message),
      };

    case UPDATE_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: updateMessageState(state, action.payload),
      };

    case SELECT_MESSAGE:
      return {
        ...state,
        selectedMessage: action.payload,
      };
    case WEBSOCKET_BOT_EXIT_CONVERSATION:
      return {
        ...state,
        messages: addSystemMessage(state, {
          external_id: uuidv4(),
          created_at: new Date(),
          type: 'exit_conversation',
          is_question: false,
          message: 'Risa AI meninggalakan percakapan',
        }),
      };
    case WEBSOCKET_BOT_IS_TYPING:
      return {
        ...state,
        botGenerateAnswerError: false,
        botIsTyping: true,
      };
    case WEBSOCKET_BOT_ANSWER_ERROR:
      return {
        ...state,
        botIsTyping: false,
        botGenerateAnswerError: true,
      };
    case WEBSOCKET_FLOATING_SPEECH_EMBED:
      return {
        ...state,
        showFloatingSpeech: action.payload,
      };
    default:
      return state;
  }
};

export default Chat;
