import React from 'react';
import ActiveStar from './Icon/ActiveStar';
import InactiveStar from './Icon/InactiveStar';

const RatingStar = ({ rating, handleSetReview, isReadOnly, size = 24 }) => {
  return (
    <>
      <div className="flex border-[1px] justify-center px-[20] py-[20px]">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            width: '100%',
            justifyContent: 'center',
            marginBottom: 2,
          }}
        >
          {Array.from(Array(5), (e, i) => {
            return (
              <div
                style={{
                  cursor: 'pointer',
                }}
                key={i}
                onClick={
                  !isReadOnly
                    ? (e) => {
                        handleSetReview(i + 1);
                      }
                    : () => {}
                }
              >
                {rating >= i + 1 ? <ActiveStar /> : <InactiveStar />}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RatingStar;
