import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  ConversationHeader,
  MessageSeparator,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import EndChatConfirmation from 'components/EndChatConfirmation';
import { post } from 'network/http/api';
import { connect, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ChatTypingIndicator from './ChatTypingIndicator';
import { clearMessage } from 'store/actions';

// Styles
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import WebSocketStatus from 'components/WebSocketStatus';
import GroupMessage from './GroupMessage';
import Image from 'components/Image';
import ChatSidebar from './ChatSidebar';
import { deleteCookie } from 'components/helper';
import RatingForm from 'components/RatingForm';

const ConversationUIKit = ({
  conversation,
  onGetMessages,
  onAddMessage,
  messages,
  selectedMessage,
  fetchingDetail,
  disableInput,
  hideInputBox,
  botGenerateAnswerError,
  isQuestion,
  disableHeader,
  isLoading,
  isRequested,
  placeholder = 'Tulis pertanyaan...',
  disableSidebar = true,
  isOpenEndChat,
  setIsOpenEndChat,
}) => {
  let inputRef = useRef();
  const [loadedMessage, setLoadedMessage] = useState([]);
  const [messageGroups, setMessageGroups] = useState([]);
  const [conversationId, setConversationId] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingEndChat, setIsLoadingEndChat] = useState(false);
  const [lastMessageUnix, setLastMessageUnix] = useState(0);
  const [openRating, setOpenRating] = useState(false);
  const dispatch = useDispatch();

  const msgListRef = useRef();
  const scrollToBottom = () => {
    msgListRef.current.scrollToBottom('smooth');
  };

  useEffect(() => {
    const { groups, nextGroups } = messages;

    setConversationId(conversation.id);
    if (groups !== messageGroups) {
      setMessageGroups(groups);
    }

    setLoadedMessage(nextGroups);
  }, [conversation.id, messageGroups, messages]);

  // scroll to bottom when new message received
  useEffect(() => {
    scrollToBottom();
  }, [messageGroups]);

  useEffect(() => {
    if (!conversation.id) {
      return;
    }

    if (fetchingDetail) {
      return;
    }

    if (conversationId === conversation.id) {
      return;
    }

    setMessageGroups([]);
    setLoadedMessage([]);
    onGetMessages(conversation.id);
    setConversationId(conversation.id);
  }, [conversation, conversationId, fetchingDetail]);

  useEffect(() => {
    if (loadingMore !== isRequested) {
      setLoadingMore(isRequested);
    }
  }, [isRequested, loadingMore]);

  const handleOnSubmit = (value) => {
    const message = {
      external_id: uuidv4(),
      conversation_id: conversation.id,
      is_question: isQuestion,
      message: value,
      type: 'text',
      createdAt: new Date(),
    };

    onAddMessage(message);
    scrollToBottom();
  };

  const handleSubmitRating = ({ rating, comment }) => {
    setIsLoadingEndChat(true);
    post(`/embed/v1/conversation/close`, {
      rating: rating,
      comment: comment,
    })
      .then((resp) => {
        dispatch(clearMessage());
        deleteCookie('external_conversation_id');
        deleteCookie('risa_visitor_id');
        setIsOpenEndChat(false);
        setOpenRating(false);
        setIsLoadingEndChat(false);
      })
      .catch((err) => {
        setIsOpenEndChat(false);
        setIsLoadingEndChat(false);
      });
  };

  const handleCloseConvo = () => {
    setIsLoadingEndChat(true);
    post('/embed/v1/conversation/close')
      .then((resp) => {
        setIsLoadingEndChat(false);
        setIsOpenEndChat(false);
        setOpenRating(true);
      })
      .catch((err) => {
        console.error(err);
        setIsLoadingEndChat(false);
      });
  };

  const onYReachStart = () => {
    const { count, groups, hasNext, lastMessageUnixTime } = messages;

    if (!conversation.id) {
      return;
    }

    if (loadingMore && !isRequested) {
      setLoadingMore(false);
    }

    if (!hasNext) {
      setLoadingMore(false);
      return;
    }

    if (loadingMore) {
      return;
    }

    if (!groups) {
      return;
    }

    if (lastMessageUnix === lastMessageUnixTime) {
      return;
    }

    /* fetch from API */
    setLoadingMore(true);
    onGetMessages(conversation.id, parseInt(lastMessageUnixTime));
    setLastMessageUnix(lastMessageUnixTime);
  };

  return (
    <>
      <MainContainer responsive>
        {!disableSidebar && <ChatSidebar />}
        <ChatContainer>
          {!disableHeader && (
            <ConversationHeader>
              <Avatar src={conversation.title} />
              <ConversationHeader.Content userName="Risa AI">
                <div>{conversation.title}</div>
                <WebSocketStatus />
                {isLoading && (
                  <div className="chat-loader">
                    <Image src="/reload-icon.svg" /> Sedang memuat chat...
                  </div>
                )}
              </ConversationHeader.Content>
            </ConversationHeader>
          )}
          <MessageList
            ref={msgListRef}
            // loadingMore={loadingMore}
            loading={isRequested || fetchingDetail}
            onYReachStart={onYReachStart}
            typingIndicator={<ChatTypingIndicator />}
          >
            {/* LOAD OLDER MESSAGE */}
            {loadedMessage &&
              loadedMessage.map((group) => {
                return (
                  <GroupMessage
                    group={group}
                    scrollToBottom={scrollToBottom}
                    selectedMessage={selectedMessage}
                  />
                );
              })}

            {/* LOAD NEWER MESSAGE */}
            {messageGroups &&
              messageGroups.map((group) => {
                return (
                  <GroupMessage
                    group={group}
                    scrollToBottom={scrollToBottom}
                    selectedMessage={selectedMessage}
                  />
                );
              })}

            {botGenerateAnswerError && (
              <MessageSeparator content="Content from property">
                <p style={{ color: 'red' }}>
                  Sorry AI failed to generate answer, please to try again.
                </p>
              </MessageSeparator>
            )}

            {isOpenEndChat ? (
              <EndChatConfirmation
                setIsOpenEndChat={setIsOpenEndChat}
                handleOnClose={handleCloseConvo}
                isLoading={loadingEndChat}
              />
            ) : null}

            {openRating ? (
              <RatingForm
                handleSubmitRating={handleSubmitRating}
                handleOnClose={() => setOpenRating(false)}
              />
            ) : null}
          </MessageList>

          <MessageInput
            style={{ opacity: hideInputBox ? 0 : 1 }}
            ref={inputRef}
            autoFocus={disableInput}
            disabled={disableInput || isOpenEndChat}
            attachButton={false}
            onSend={handleOnSubmit}
            placeholder={placeholder}
          />
        </ChatContainer>
      </MainContainer>
    </>
  );
};

const mapStateToProps = ({ chat, ConversationDetail }) => {
  return {
    botGenerateAnswerError: chat.botGenerateAnswerError,
    addMessageRequested: chat.addMessageRequested,
    conversation: ConversationDetail.conversation,
  };
};

export default connect(mapStateToProps, {
  clearMessage,
})(ConversationUIKit);
